import { StyleSheet, Text, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import { colors, formatDateStringToHumanReadable } from "../../constants";
import { FontAwesome } from "@expo/vector-icons";

const RefreshIconAndStatus = ({ navigation }) => {
    const [isloading, setIsloading] = useState(false);
    const [handleRefresh, setHandleRefresh] = useState(0)
    return (
        <TouchableOpacity
            onPress={() => {
                setIsloading(true);
                setHandleRefresh((prevState => prevState + 1))
                setIsloading(false);
            }
            }
            style={styles.refreshTimeContainer}>
            <FontAwesome name="refresh" size={24} color="black" />
            <Text style={styles.secondarytextSm}>{formatDateStringToHumanReadable(Date.now())}</Text>
        </TouchableOpacity>
    );
};

export default RefreshIconAndStatus;

const styles = StyleSheet.create({
    refreshTimeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    secondarytextSm: {
        color: colors.muted,
        fontSize: 13,
    },
    cartIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bisque,
        padding: 5,
        borderRadius: 8
    },
    iconContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 3
    }
});
