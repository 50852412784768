import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { network, colors } from "../../constants";

const FAQScreen = ({ navigation, route }) => {
  const [showBox, setShowBox] = useState(true);
  const [error, setError] = useState("");

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack(null);
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Sıkça Sorulan Sorular (SSS)</Text>
      </View>
      <View style={styles.OptionsContainer}>
        <ScrollView>
          <View style={styles.primaryTextContainer}>
            <Text style={styles.primaryText}>Alıcılar İçin Sıkça Sorulan Sorular</Text>
          </View>
          <Text>
            {`
            1. Nasıl hesap oluşturabilirim?
              - Bir hesap oluşturmak için "Üye Ol" düğmesine tıklayın ve kayıt işlemini takip edin.
      
            2. Bir kitap nasıl satın alınır?
              - Listeleri gözden geçirin, istediğiniz kitabı seçin ve satın almayı tamamlamak için talimatları izleyin.
      
            3. Ödemeler nasıl işlenir?
              - Ödemeler, ödeme geçidi aracılığıyla güvenli bir şekilde işlenir. Çeşitli ödeme yöntemlerini kabul ediyoruz.
      
            4. Kötü durumda bir kitap alırsam ne yapmalıyım?
              - Alıcıları, sorunları doğrudan satıcılarla iletişime geçmeye teşvik ederiz. Sorun çözülmezse, destek ekibimizle iletişime geçebilirsiniz.
      
            5. Anlaşmazlık çıkarsa ne olur?
              - Anlaşmazlıkları önce satıcıyla çözmenizi öneririz. Gerekirse, destek ekibimizden yardım alabilirsiniz.
      
            6. Kişisel bilgilerim güvende mi?
              - Veri güvenliğini ciddiye alıyoruz. Bilgileriniz Gizlilik Politikamızda belirtildiği şekilde korunmaktadır.
      
            7. Müşteri destek ile nasıl iletişime geçebilirim?
              - Müşteri destek ile iletişime geçmek için 3 seçeneğiniz vardır.
              i) destek@raftankitap.com e-posta adresine e-posta atabilirsiniz.
              ii) Eğer siparişinizle ilgili bir probleminiz varsa, Hesap & Ayarlar > Sipariş Ettiğim Kitaplar > Problem Bildir butonuna tıklayabilirsiniz.
              Eğer teslimattan sonra 5 gün geçmişse bu buton kaybolacaktır.
              iii) Eğer başka bir sorunuz varsa, Hesap & Ayarlar > Hata, Öneri veya Sorun Bildir sayfasını ziyaret edebilirsiniz.
      
            Daha fazla sorunuz varsa, bizimle iletişime geçmekten çekinmeyin. Yardımcı olmak için buradayız!
            `}
          </Text>
          <View style={styles.primaryTextContainer}>
            <Text style={styles.primaryText}>Satıcılar İçin Sıkça Sorulan Sorular</Text>
          </View>
          <Text>
            {`
            1. Nasıl hesap oluşturabilirim?
              - Bir hesap oluşturmak için "Üye Ol" düğmesine tıklayın ve kayıt işlemini takip edin.
      
            2. Bir kitabı nasıl satışa çıkarabilirim?
              - Hesabınıza giriş yapın, "Kitap Sat" seçeneğine tıklayın ve kitap hakkındaki gerekli bilgileri sağlayın.
      
            3. Ödemeler nasıl işlenir?
              - Ödemeler, ödeme geçidi aracılığıyla güvenli bir şekilde işlenir. Çeşitli ödeme yöntemlerini kabul ediyoruz.
      
            4. Bir listeyi nasıl düzenler veya silerim?
              - Hesabınıza giriş yapın, listelerinize gidin ve düzenlemek veya silmek istediğiniz kitabı seçin.
      
            5. Kişisel bilgilerim güvende mi?
              - Veri güvenliğini ciddiye alıyoruz. Bilgileriniz Gizlilik Politikamızda belirtildiği şekilde korunmaktadır.
      
            6. DVD gibi diğer medya türlerini listeleyebilir miyim?
              - Şu anda yalnızca ikinci el kitap satışını destekliyoruz.
      
            7. Müşteri destek ile nasıl iletişime geçebilirim?
              - Müşteri destek ile iletişime geçmek için "Bize Ulaşın" sayfasını ziyaret edebilirsiniz.

            8. Satış yaptım. Kazancım, ne zaman benim banka hesabıma yatar?
              - Raf gelirlerinin alınabilmesi için öncelikle ürünün alıcıya teslim edilmesi gerekmektedir. Ürünün 
              teslim edilmesinin ardından, alıcıya en az 5 gün süre veriyoruz. Bu süre içinde alıcı herhangi 
              bir sorunla karşılaşırsa, Raf platformuna bildirebilir ve ödemesini geri isteyebilir. Raf ekibi, problemle
              ilgi kanıtları isteyerek durumu çözer ve alıcıya parasını geri verebilir. 5 günlük süre sonunda 
              eğer alıcı herhangi bir sorun bildirmezse, gelirleriniz aşağıdaki takvime göre banka hesabınıza yatırılacaktır.

              Ürün teslim tarihi 1-10 arasındaysa, ödeme tarihi mevcut ayın 15'i olacaktır:
              - Örnek 1: Ürün teslim tarihi 4 Ağustos'ta ise, ödeme tarihi 15 Ağustos olacaktır.
              - Örnek 2: Ürün teslim tarihi 7 Mayıs'ta ise, ödeme tarihi 15 Mayıs olacaktır.
              - Örnek 3: Ürün teslim tarihi 2 Şubat'ta ise, ödeme tarihi 15 Şubat olacaktır.
              - Örnek 4: Ürün teslim tarihi 10 Ekim'de ise, ödeme tarihi 15 Ekim olacaktır.

              Ürün teslim tarihi 11-23 arasındaysa, ödeme tarihi sonraki ayın 1'i olacaktır:
              - Örnek 1: Ürün teslim tarihi 19 Temmuz'da ise, ödeme tarihi 1 Ağustos olacaktır.
              - Örnek 2: Ürün teslim tarihi 15 Ocak'ta ise, ödeme tarihi 1 Şubat olacaktır.
              - Örnek 3: Ürün teslim tarihi 20 Eylül'de ise, ödeme tarihi 1 Ekim olacaktır.
              - Örnek 4: Ürün teslim tarihi 23 Şubat'ta ise, ödeme tarihi 1 Mart olacaktır.

              Ürün teslim tarihi 24-31 arasındaysa, ödeme tarihi sonraki ayın 15'i olacaktır:
              - Örnek 1: Ürün teslim tarihi 28 Aralık'ta ise, ödeme tarihi 15 Ocak olacaktır.
              - Örnek 2: Ürün teslim tarihi 31 Temmuz'da ise, ödeme tarihi 15 Ağustos olacaktır.
              - Örnek 3: Ürün teslim tarihi 25 Nisan'da ise, ödeme tarihi 15 Mayıs olacaktır.
              - Örnek 4: Ürün teslim tarihi 30 Ekim'de ise, ödeme tarihi 15 Kasım olacaktır.

            Bu örnekler, belirtilen tarih aralıklarına göre ödeme tarihlerini daha iyi anlamanıza yardımcı olabilir.
      
            Daha fazla sorunuz varsa, bizimle iletişime geçmekten çekinmeyin. Yardımcı olmak için buradayız!
            `}
          </Text>
          <View style={styles.primaryTextContainer}>
            <Text style={styles.primaryText}>İletişime Geçin</Text>
          </View>
          <Text>{`
            Bizimle İletişime Geçin:
            Sizden haber almak istiyoruz! Sorularınız, geri bildirimleriniz veya sadece merhaba demek için bize ulaşmaktan çekinmeyin. Sosyal medyada bizi takip ederek güncellemeleri, kitap önerilerini ve daha fazlasını bulabilirsiniz.
            - Emailimiz: destek@raftankitap.com 
            `}
          </Text>
        </ScrollView>
      </View>
    </View>
  );
};

export default FAQScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContianer: {
    width: "100%",
    height: "25%",
  },
  primaryTextContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  primaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
    flex: 1,
    justifyContent: 'center'
  },
});
