import {
  StyleSheet,
  StatusBar,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Modal,
  Image,
  KeyboardAvoidingView
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import React, { useState, useEffect, useRef, useCallback } from "react";
import BasicProductList from "../../components/BasicProductList/BasicProductList";
import { colors, messages, network, ORDER_STATUS } from "../../constants";
import CustomButton from "../../components/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../states/actionCreators/actionCreators";
import { bindActionCreators } from "redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import { useToast } from "react-native-toast-notifications";
import { TextInput } from "react-native-gesture-handler";
import { useAuth } from "../../context/AuthContext";
const CheckoutScreen = ({ navigation, route }) => {
  const { user, setUserData, clearUserData } = useAuth();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const cartProduct = useSelector((state) => state.product);
  console.log("cartProduct", cartProduct)
  const dispatch = useDispatch();
  const { emptyCart } = bindActionCreators(actionCreators, dispatch);
  const creditCardRef = useRef();
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");

  const [city, setCity] = useState("");
  const [email, setEmail] = useState("")
  const [streetAddress, setStreetAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("")
  const [disabled, setDisabled] = useState(true);
  const [buyerFirstName, setBuyerFirstName] = useState("")
  const [buyerLastName, setBuyerLastName] = useState("")
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const toast = useToast();


  const handleCardNumberChange = (text) => {
    // Implement card number formatting logic here (e.g., adding spaces)
    setCardNumber(text);
  };

  //method to remove the userData from aysnc storage and navigate to login
  const logout = async () => {
    clearUserData();
    navigation.replace("login");
  };

  const handleCreditCardInput = useCallback(() => {
    if (creditCardRef.current) {
      const { error, data } = creditCardRef.current.submit();
      console.log('ERROR: ', error);
      console.log('CARD DATA: ', data);
    }
  }, []);

  const validateEntries = () => {
    if (streetAddress && city && country && zipcode && phone && email) {
      return true;
    } else {
      return false;
    }
  }

  //method to handle checkout
  const handleCheckout = async () => {
    if (creditCardRef.current) {
      const { error, data } = creditCardRef.current.submit();
      console.log('CARD ERROR: ', error);
      console.log('CARD DATA: ', data);
    }

    setIsLoading(true);
    var myHeaders = new Headers();
    try {
      myHeaders.append("x-auth-token", user?.token);
      myHeaders.append("Content-Type", "application/json");

      var payload = [];
      var totalAmount = 0;

      // fetch the cart items from redux and set the total cost

      cartProduct.forEach((product) => {
        let obj = {
          product: product._id,
          price: product.price,
          quantity: product.quantity,
          status: ORDER_STATUS.SIPARIS_EDILDI
        };
        totalAmount += (product.price);
        payload.push(obj);
      });
    } catch (error) {
      setIsLoading(false);
      toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
      console.log("error", error);
    }

    var raw = JSON.stringify({
      items: payload,
      totalAmount: totalAmount,
      discount: 0,
      payment_type: "cod",
      country: country,
      city: city,
      zipcode: zipcode,
      shippingAddress: streetAddress,
      buyerFirstName: buyerFirstName,
      buyerLastName: buyerLastName,
      buyerPhone: phone,
      buyerEmail: email,
      cvv: cvv,
      cardNumber: cardNumber,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };


    fetch(network.serverip + "/checkout", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Checkout=>", result);
        if (result.err === "jwt expired") {
          setIsLoading(false);
          logout();
        }
        if (result.success == true) {
          setIsLoading(false);
          emptyCart("empty");
          navigation.replace("orderconfirm");
        } else {
          setIsLoading(false);
          toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        console.log("error", error);
      });
    setIsLoading(false);
  };

  // set the address and total cost on initital render
  useEffect(() => {
    if (streetAddress && city && country) {
      setAddress(`${streetAddress}, ${city}, ${country}`);
    } else {
      setAddress("");
    }
    let accumulatorForTotalCost = 0

    cartProduct.forEach((product) => {
      accumulatorForTotalCost += product.price * product.quantity;
    })

    setTotalCost(accumulatorForTotalCost);
    console.log("totalCost needs to be total", cartProduct, accumulatorForTotalCost)
  }, [cartProduct]);

  return (
    <KeyboardAvoidingView style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <ProgressDialog visible={isLoading} label={"Sipariş Veriliyor..."} />
      <View style={styles.topBarContainer}>
        <TouchableOpacity
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack(); // leave goBack since we can come here from viewofferssent, or cart
            } else {
              navigation.navigate("cart")
            }
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
        <View></View>
        <View></View>
      </View>
      <ScrollView style={styles.bodyContainer} nestedScrollEnabled={true}>
        <Text style={styles.primaryText}>Sipariş Özeti</Text>

        <ScrollView
          style={styles.orderSummaryContainer}
          nestedScrollEnabled={true}
        >
          {cartProduct.map((product, index) => (
            <BasicProductList
              key={index}
              imageUri={`${network.serverip}/uploads/${product.filename}`}
              title={product?.title}
              price={product?.price}
              seller={product?.sellerUser?.username}
              quantity={product.quantity}
              description={product?.description}
              status={product?.status}
            />
          ))}
        </ScrollView>
        <View>
          <Text style={styles.primaryText}>Toplam</Text>
        </View>
        <View style={styles.totalOrderInfoContainer}>
          <View style={styles.list}>
            <Text style={styles.label}>Sipariş</Text>
            <Text>{totalCost}₺</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.label}>Kargo Parası</Text>
            <Text style={styles.tertiaryTextSm}>0 ₺ {"("}Bedava Kargo{")"}</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.label}>KDV</Text>
            <Text style={styles.tertiaryTextSm}>0 ₺ {"("}Fiyatlara KDV dahildir{")"}</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.primaryTextSm}>Toplam</Text>
            <Text style={styles.secondaryTextSm}>{totalCost}₺</Text>
          </View>
        </View>
        <View>
          <Text style={styles.primaryText}>Gönderim Adresi</Text>
        </View>
        <View style={styles.listContainer}>
          <Text style={styles.label}>Alıcı Adı</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Alıcı Adını girin"} value={buyerFirstName} onChangeText={setBuyerFirstName}></TextInput>
          <Text style={styles.label}>Alıcı Soyadı</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Alıcı Soyadını girin"} value={buyerLastName} onChangeText={setBuyerLastName}></TextInput>
          <Text style={styles.label}>E-posta</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"E-postanızı girin"} value={email} onChangeText={setEmail}></TextInput>
          <Text style={styles.label}>Telefon</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Telefonuzu girin"} value={phone} onChangeText={setPhone}></TextInput>
        </View>
        <View>
          <Text style={styles.primaryText}>Adres</Text>
        </View>
        <View style={styles.listContainer}>
          <TouchableOpacity
            style={styles.list}
            onPress={() => setModalVisible(true)}
          >
            <Text style={styles.label}>Adres</Text>
            <View>
              {country || city || streetAddress != "" ? (
                <Text
                  style={styles.secondaryTextSm}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {address.length < 25
                    ? `${address}`
                    : `${address.substring(0, 25)}...`}
                </Text>
              ) : (
                <Text style={styles.primaryTextSm}>Ekle</Text>
              )}
            </View>
          </TouchableOpacity>
        </View>
        <View>
          <Text style={styles.primaryText}>Ödeme Bilgileri</Text>
        </View>
        <View style={styles.listContainer}>
          <View style={styles.list}>
            <Text style={styles.secondaryTextSm}>Ödeme Yöntemi</Text>
            <Text style={styles.primaryTextSm}>Kredi Karti İle</Text>
          </View>
          {/* <CreditCard ref={creditCardRef} /> */}
          {/* <CreditCartInput /> */}
       
            <Text style={styles.label}>Kredi Kart Numarası</Text><Text style={styles.subText}>(Arada boşluk bırakmayın)</Text>
            <TextInput
              style={styles.input}
              placeholder="XXXX XXXX XXXX XXXX"
              placeholderTextColor={colors.muted}
              value={cardNumber}
              autoComplete="cc-number"
              onChangeText={handleCardNumberChange}
              keyboardType="numeric"
            />
            <Text style={styles.label}>Son Kullanma Tarihi Ayı</Text><Text style={styles.subText}>(İki sayıyla belirtin. Mesela üçüncü ay ise 03, onuncu ay ise 10)</Text>
            <TextInput
              style={styles.input}
              placeholder="MM"
              placeholderTextColor={colors.muted}
              value={expiryMonth}
              autoComplete="cc-exp-month"
              onChangeText={setExpiryMonth}
              keyboardType="numeric"
            />
            <Text style={styles.label}>Son Kullanma Tarihi Yılı</Text>
            <TextInput
              style={styles.input}
              placeholder="YYYY"
              placeholderTextColor={colors.muted}
              value={expiryYear}
              autoComplete="cc-exp-year"
              onChangeText={setExpiryYear}
              keyboardType="numeric"
            />
            <Text style={styles.label}>CVV Kodu</Text>
            <TextInput
              style={styles.input}
              placeholder="XXX"
              placeholderTextColor={colors.muted}
              value={cvv}
              autoComplete="cc-csc"
              onChangeText={(text) => setCvv(text)}
              keyboardType="numeric"
              secureTextEntry={true}
            />
          {/* <CreditCardInput onChange={handleCreditCardInput}/> */}
        </View>

        <View style={styles.emptyView}></View>
      </ScrollView>
      <View style={styles.buttomContainer}>
        <CustomButton
          onPress={() => {
            handleCheckout();
          }}
          text={"SİPARİŞ VER"} disabled={!validateEntries()}
        />
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.modelBody}>
          <View style={styles.modelAddressContainer}>
            <CustomInput
              value={country}
              setValue={setCountry}
              placeholder={"Ülke Girin"}
            />
            <CustomInput
              value={city}
              setValue={setCity}
              placeholder={"Şehir Girin"}
            />
            <CustomInput
              value={streetAddress}
              setValue={setStreetAddress}
              placeholder={"Sokak Adresi Girin"}
            />
            <CustomInput
              value={zipcode}
              setValue={setZipcode}
              placeholder={"Posta Kodu Girin"}
              keyboardType={"number-pad"}
            />
            {streetAddress || city || country != "" ? (
              <CustomButton
                onPress={() => {
                  setModalVisible(!modalVisible);
                  setAddress(`${streetAddress}, ${city}, ${country}`);
                }}
                text={"Kaydet"}
              />
            ) : (
              <CustomButton
                onPress={() => {
                  setModalVisible(!modalVisible);
                }}
                text={"Kapat"}
              />
            )}
          </View>
        </View>
      </Modal>
    </KeyboardAvoidingView>
  );
};

export default CheckoutScreen;

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  subText: {
    color: colors.muted,
    fontWeight: 'normal',
    fontSize: 14,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 16,
  },
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 0,
    flex: 1,
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  toBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  bodyContainer: {
    flex: 1,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  customInput: {
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: 'black'
  },
  orderSummaryContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    maxHeight: 220,
  },
  totalOrderInfoContainer: {
    borderRadius: 10,
    padding: 10,
    backgroundColor: colors.white,
  },
  primaryText: {
    marginBottom: 5,
    marginTop: 5,
    fontSize: 20,
    fontWeight: "bold",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.white,
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: colors.light,
    padding: 10,
  },
  primaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
    color: colors.primary,
  },
  secondaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
  },
  tertiaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
    color: "green",
  },
  listContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
  },
  buttomContainer: {
    width: "100%",
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  emptyView: {
    width: "100%",
    height: 20,
  },
  modelBody: {
    flex: 1,
    display: "flex",
    flexL: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modelAddressContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    width: 320,
    height: 400,
    backgroundColor: colors.white,
    borderRadius: 20,
    elevation: 3,
  },
});
