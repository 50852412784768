const DATE_FORMAT_OPTIONS = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
}

export const formatDateStringToHumanReadable = (dateString) => {

    // Example usage:
    // const date = '2023-09-21T23:38:49.001Z';
    // const humanReadableDate = formatDateStringToHumanReadable(date);
    // console.log(humanReadableDate); // Output: "September 21, 2023, 23:38:49"
    const dateObject = new Date(dateString);

    // Check if the dateObject is a valid date (not NaN)
    if (isNaN(dateObject)) {
        return 'Tarih bulunamadı';
    }
    return dateObject.toLocaleString('tr-TR', DATE_FORMAT_OPTIONS);
}
export default DATE_FORMAT_OPTIONS;