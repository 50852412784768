import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { network, colors } from "../../constants";

const PrivacyScreen = ({ navigation, route }) => {
  const [showBox, setShowBox] = useState(true);
  const [error, setError] = useState("");

  return (
      <View style={styles.container}>
          <StatusBar backgroundColor={colors.primary}></StatusBar>
          <View style={styles.TopBarContainer}>
              <TouchableOpacity
                  onPress={() => {
                    navigation.goBack(null);
                  }}
              >
                  <Ionicons
                      name="arrow-back-circle-outline"
                      size={30}
                      color={colors.muted}
                  />
              </TouchableOpacity>
          </View>
          <View style={styles.screenNameContainer}>
              <Text style={styles.screenNameText}>İletişim</Text>
          </View>
          <View style={styles.OptionsContainer}>
              <ScrollView>
                  <View style={styles.primaryTextContainer}>
                      <Text style={styles.primaryText}>İletişim</Text>
                  </View>
                  <Text>{`
                  Şirket Unvanı: Raftan Yazılım İnternet Eğitim Ticaret Limited Şirketi
                  Adres: Konak Mahallesi 853 Sokak Özyurtsever İşhanı No: 13/B13 Konak / İZMİR
                  Email: destek@raftankitap.com
                  Telefon: +90 506 147 46 33
                  Vergi Dairesi: Kemeraltı Vergi Dairesi Müdürlüğü
                  Vergi Dairesi No: 7342751065
                  Güncellenme Tarihi: 08.10.2024 `}
                  </Text>
              </ScrollView>
          </View>
      </View>
  );
};

export default PrivacyScreen;

const styles = StyleSheet.create({
  container: {
      width: "100%",
      flexDirecion: "row",
      backgroundColor: colors.light,
      alignItems: "center",
      justifyContent: "flex-start",
      padding: 20,
      flex: 1,
  },
  TopBarContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
  },
  UserProfileCardContianer: {
      width: "100%",
      height: "25%",
  },
  primaryTextContainer: {
      padding: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
      paddingTop: 10,
      paddingBottom: 10,
  },
  primaryText: {
      fontSize: 20,
      fontWeight: "bold",
  },
  screenNameContainer: {
      marginTop: 10,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 10,
  },
  screenNameText: {
      fontSize: 30,
      fontWeight: "800",
      color: colors.muted,
  },
  OptionsContainer: {
      width: "100%",
      flex: 1,
      justifyContent: 'center'
  },
});
