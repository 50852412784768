import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { network, colors, messages } from "../../constants";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import { useToast } from "react-native-toast-notifications";
import { useAuth } from "../../context/AuthContext";
const MyAccountScreen = ({ navigation, route }) => {
  const {user, setUserData, clearUserData} = useAuth();
  const [error, setError] = useState("");
  const toast = useToast();

  //method for alert
  const showConfirmDialog = () => {
    console.log("showConfirmDialog pressed")
    return AlertPolyfill(
      "Onayla",
      "Hesabınızı kalıcı olarak silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
      [
        {
          text: "Evet",
          onPress: () => {
            handleDeleteAccount();
          },
        },
        {
          text: "Hayır",
        },
      ]
    );
  };

  var myHeaders = new Headers();
  myHeaders.append("x-auth-token", user?.token);
  myHeaders.append("Content-Type", "application/json");

  var deleteUserRequestOptions = {
    method: "POST",
    redirect: "follow",
    headers: myHeaders
  };

  //method to delete the account using API call
  const handleDeleteAccount = () => {
    let fetchURL = network.serverip + "/delete-user"
    console.log("fetchURL", fetchURL);
    fetch(fetchURL, deleteUserRequestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.success == true) {
          navigation.replace("landing");
          clearUserData();
          toast.show("Hesabınız başarıyla silindi", { placement: 'bottom', type: "success_with_close_button" })
        } else {
          toast.show(messages.genericErrorMessage, { placement: 'bottom', type: "danger_with_close_button" })
        }
      })
      .catch((error) => {
        toast.show(messages.genericErrorMessage, { placement: 'bottom', type: "danger_with_close_button" })
        console.log("error", error)
      }
      );
  };
  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("tabs", {screen: "profile"})
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Hesabım</Text>
      </View>
      <View style={styles.UserProfileCardContainer}>
        <UserProfileCard
          Icon={Ionicons}
          name={user?.username}
          email={user?.email}
        />
      </View>
      <View style={styles.OptionsContainer}>
        <OptionList
          text={"Şifremi Değiştir"}
          Icon={Ionicons}
          iconName={"key-sharp"}
          onPress={
            () =>
              navigation.navigate("changepassword") // navigate to changepassword
          }
        />
        <OptionList
          text={"Hesabımı Sil"}
          Icon={MaterialIcons}
          iconName={"delete"}
          type={"danger"}
          onPress={() => showConfirmDialog()}
        />
      </View>
    </View>
  );
};

export default MyAccountScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContainer: {
    width: "100%",
    height: "25%",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
  },
});
