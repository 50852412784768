import { createContext, useContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  
  // Load the user data from AsyncStorage on app startup
  useEffect(() => {
    const loadUserData = async () => {  
      try {
        const userDataJson = await AsyncStorage.getItem('userData');
        if (userDataJson) {
          const userData = JSON.parse(userDataJson);
          setUser(userData);
        } else {
          console.error('Error loading user data inside else:', userDataJson);
        }
      } catch (error) {
        // Handle error
        console.error('Error loading user data:', error);
      }
    };

    loadUserData();
  }, []);

  // Function to set the user data in both state and AsyncStorage
  const setUserData = async (userData) => {
    try {
      setUser(userData);
      console.log("setUserData called with userData:", userData)
      const userDataJson = JSON.stringify(userData);
      await AsyncStorage.setItem('userData', userDataJson);
    } catch (error) {
      // Handle error
      console.error('Error setting user data:', error);
    }
  };

  // Function to clear the user data from both state and AsyncStorage
  const clearUserData = async () => {
    try {
      await AsyncStorage.removeItem('userData');
      setUser(null);
    } catch (error) {
      // Handle error
      console.error('Error clearing user data:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUserData, clearUserData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
