import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  Image,
  ScrollView,
  KeyboardAvoidingView, SafeAreaView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { network, colors, messages } from "../../constants";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import ProgressDialog from "react-native-progress-dialog";
import * as ImagePicker from "expo-image-picker";
import { AntDesign } from "@expo/vector-icons";
import { useToast } from "react-native-toast-notifications";
import { useAuth } from "../../context/AuthContext";
const FEEDBACK_TEMPLATE = `
Hata/Geri Bildirim/Çökme Raporu Şablonu

Rapor Tarihi: [Tarih]

Raporu Oluşturan: [Adınız/Kullanıcı Adınız]

E-posta: [E-posta Adresiniz]

Platform/İşletim Sistemi: [Örneğin, Tarayıcı, iPhone iOS 10, Android 11]

Açıklama:

Bildirim Türü: [Hata, Geri Bildirim, Çökme] [Sorunun türünü belirtin, örneğin, "Hata" eğer bir şey beklenen şekilde çalışmıyorsa, "Geri Bildirim" eğer bir öneriniz veya yorumunuz varsa, "Çökme" eğer yazılım çöküyorsa]

Özet: [Sorunun veya geri bildirimin kısa, bir cümlelik açıklaması]

Detaylı Açıklama: [Sorunu veya geri bildirimi detaylı bir şekilde açıklayın. Sorunu nasıl yeniden üreteceğiniz adımları içeriyorsa, bu adımları belirtin. Çökmeler için, çökme öncesinde ne yaptığınızı belirtin.]

Beklenen Davranış: [Ürünü veya yazılımı kullanırken ne beklediğinizi açıklayın.]

Gerçekleşen Davranış:[Gerçekte ne olduğunu, hata mesajlarını veya beklenmeyen davranışları dahil ederek açıklayın.]

Ciddiyet Düzeyi: [Bir ciddiyet düzeyi seçin: Düşük, Orta, Yüksek, Kritik]

Ek Bilgiler: [Ek bilgi vermek istiyorsaniz burada belirtin]

Sıklık: [Sorun ne sıklıkla meydana geliyor? Her zaman, Bazen, Nadiren, Bir Kez]
`

const Feedback = ({ navigation, route }) => {
  const {user, setUserData, clearUserData} = useAuth();
  const [feedbackContent, setFeedbackContent] = useState(FEEDBACK_TEMPLATE);
  const [feedbackTitle, setFeedbackTitle] = useState("Hata, Öneri veya Sorun Bildirmek İstiyorum")
  const [isloading, setIsloading] = useState(false);
  const [image, setImage] = useState("");
  const toast = useToast();

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }


  //Method for selecting the image from device gallery
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
    });

    if (!result.cancelled) {
      console.log("result", result);
      setImage(result.uri);
    }
  };

  // method to send feedback via REST API
  const sendFeedbackHandle = async () => {
    if (feedbackTitle === "") {
      toast.show("Lütfen başlığı boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      })

    } else if (feedbackContent === "") {
      toast.show("Lütfen metni boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      })
    } else {

      var myHeaders = new Headers();
      myHeaders.append("x-auth-token", user?.token);
      var formdata = new FormData();
      try {
        if (image != "") {
          // converts to blob. Source: https://stackoverflow.com/a/53454470
          const blob = dataURItoBlob(image);
          const uploadedImage = new File([blob], "upload.jpg", {
            type: 'image/jpeg'
          });
          formdata.append("image", uploadedImage);
        }

        formdata.append("feedbackContent", feedbackContent);
        formdata.append("feedbackTitle", feedbackTitle);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata
        };

        fetch(
          network.serverip + "/feedback",
          requestOptions
        ) // API call
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              toast.show("Form başarıyla gönderildi.", { placement: "bottom", type: "success_with_close_button" });
              setIsloading(false);
            } else {
              toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
              setIsloading(false);
            }
          })
          .catch((error) => {
            toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
            console.log("error", error.message);
            setIsloading(false);
          });
      } catch (error) {
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
        console.log("error", error.message);
        setIsloading(false);
      }
    }

  };
  return (
    <View style={styles.container}>
    <ScrollView>
      <ProgressDialog visible={isloading} label={"Yükleniyor..."} />
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack(null);
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Hata, Öneri veya Sorun Bildir</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>
            Bir hata, öneri veya sorun varsa buradan bize bildirebilirsiniz.
          </Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <View style={styles.imageContainer}>
          <Text style={{ color: colors.muted }}>Ek Fotoğraf Buraya Ekleyebilirsiniz (Zorunlu Değil)</Text>
          {image ? (
            <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
              <Image
                source={{ uri: image }}
                style={{ width: 200, height: 200 }}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
              <AntDesign name="pluscircle" size={50} color={colors.muted} />
            </TouchableOpacity>
          )}
        </View>
        <Text style={{ color: colors.muted }}>Başlık</Text>
        <CustomInput
          value={feedbackTitle}
          setValue={setFeedbackTitle}
          placeholder={"Bir başlık girin"}
        />
        <Text style={{ color: colors.muted }}>Detaylar</Text>
        <CustomInput
          value={feedbackContent}
          multiline
          editable
          numberOfLines={12}
          maxLength={10000}
          setValue={setFeedbackContent}
          height={350}
        />
      </View>
      <CustomButton
        text={"GÖNDER"}
        onPress={(e) => sendFeedbackHandle(e) && setIsloading(true)}
        radius={5}
      />
    </ScrollView>
    </View>
  );
};

export default Feedback;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.light,
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  formContainer: {
    marginTop: 10,
    marginBottom: 20,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    minHeight: 100,
    flexDirecion: "row",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: 250,
    backgroundColor: colors.white,
    borderRadius: 10,
    elevation: 5,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 5
  },
  imageHolder: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.light,
    borderRadius: 10,
    elevation: 5,
  },
});
