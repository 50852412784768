import React from 'react';
import { colors } from '../../constants';
import { View, Image, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
const AskLogin = ({}) => {
    const navigation = useNavigation();
    return (
        <View style={styles.container}>
            <View>
                <Text style={{fontSize: 16, fontWeight:"bold"}}>Seansınızın süresi bitti veya üye değilsiniz...</Text>
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity onPress={(e) => navigation.navigate("login")} style={styles.button}>
                    <Text style={styles.buttonText}>GİRİŞ YAP</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={(e) => navigation.navigate("signup")} style={styles.button}>
                    <Text style={styles.buttonText}>ÜYE OL</Text>
                </TouchableOpacity>
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        padding: 10
    },
    buttonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    button: {
        backgroundColor: colors.blue,
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 10,
        marginLeft: 10,
    },
    buttonText: {
        color: '#fff',
        fontWeight: "bold",
    },
});

export default AskLogin;
