import { StyleSheet, Image, TouchableOpacity } from "react-native";
import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Ionicons } from "@expo/vector-icons";
import HomeScreen from "../../screens/user/HomeScreen";
import { colors } from "../../constants";
import UserProfileScreen from "../../screens/user/UserProfileScreen";
import HomeIconActive from "../../assets/icons/bar_home_icon_active.png";
import HomeIcon from "../../assets/icons/bar_home_icon.png";
import UserIcon from "../../assets/icons/bar_profile_icon.png";
import UserIconActive from "../../assets/icons/bar_profile_icon_active.png";
import SellerScreen from "../../screens/user/SellerScreen";

const Tab = createBottomTabNavigator();

const Tabs = ({ navigation, route }) => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarHideOnKeyboard: true,
        tabBarStyle: [
          {
            display: "flex",
          },
          null,
        ],
        headerShown: false,
        tabBarShowLabel: true,
        tabBarActiveTintColor: colors.primary,
        tabBarStyle: {
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          backgroundColor: colors.white,
          borderWidth: 2,
          borderColor: colors.white,
          borderTopColor: "white"
        },
      })}
    >
      <Tab.Screen
        name="buy"
        component={HomeScreen}
        options={{
          tabBarLabel: 'Satın Al',
          tabBarIcon: ({ focused }) => (
            <TouchableOpacity disabled>
              <Ionicons
                name="home"
                size={29}
                color={focused == true ? colors.primary : colors.muted}
              />
            </TouchableOpacity>
          ),
        }}
      />
      <Tab.Screen
        name="sell"
        component={SellerScreen}
        options={{
          tabBarLabel: 'Satış Yap',
          tabBarIcon: ({ focused }) => (
            <TouchableOpacity>
              <Ionicons
                name="cash-outline"
                size={29}
                color={focused == true ? colors.primary : colors.muted}
              />
            </TouchableOpacity>
          ),
        }}
      />
      {/* {
        // Wishlist is ready yet!
        <Tab.Screen
          name="Sat"
          component={CategoriesScreen}
        />
      } */}
      <Tab.Screen
        name="profile"
        component={UserProfileScreen}
        options={{
          tabBarLabel: 'Hesap & Ayarlar',
          tabBarIcon: ({ focused }) => (
            <TouchableOpacity>
              <Ionicons
                name="person-outline"
                size={29}
                color={focused == true ? colors.primary : colors.muted}
              />
            </TouchableOpacity>
          ),
        }}
      />
    </Tab.Navigator>
  );
};

export default Tabs;

const styles = StyleSheet.create({
  tabIconStyle: {
    width: 10,
    height: 10,
  },
});
