import { NavigationContainer } from "@react-navigation/native";
import LoginScreen from "../screens/auth/LoginScreen";
import SignupScreen from "../screens/auth/SignupScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import SplashScreen from "../screens/auth/SplashScreen";
import ForgetPasswordScreen from "../screens/auth/ForgetPasswordScreen";
import ChangePasswordScreen from "../screens/user/ChangePasswordScreen";
import MyAccountScreen from "../screens/user/MyAccountScreen";
import AddProductScreen from "../screens/user/AddProductScreen";
import DashboardScreen from "../screens/admin/DashboardScreen";
import ViewMyProductsScreen from "../screens/user/ViewMyProductsScreen";
import Tabs from "./tabs/Tabs";
import CartScreen from "../screens/user/CartScreen";
import CheckoutScreen from "../screens/user/CheckoutScreen.js";
import OrderConfirmScreen from "../screens/user/OrderConfirmScreen";
import ProductDetailScreen from "../screens/user/ProductDetailScreen";
import EditProductScreen from "../screens/user/EditProductScreen";
import ViewSalesScreen from "../screens/user/ViewSalesScreen";
import ViewPurchasesScreen from "../screens/user/ViewPurchasesScreen";
import ViewSaleDetailsScreen from "../screens/user/ViewSaleDetailsScreen";
import ViewPurchaseDetailsScreen from "../screens/user/ViewPurchaseDetailsScreen";
import Corporate from "../screens/user/Corporate";
import AboutScreen from "../screens/user/AboutScreen.js";
import ReturnTermsScreen from "../screens/user/ReturnTermsScreen.js";
import Feedback from "../screens/user/Feedback";
import LandingScreen from "../screens/auth/LandingScreen";
import BankDetailsScreen from "../screens/user/BankDetailsScreen";
import ViewOffersReceivedScreen from "../screens/user/ViewOffersReceivedScreen";
import ViewOffersSentScreen from "../screens/user/ViewOffersSentScreen";
import DisputeScreen from "../screens/user/DisputeScreen";
import PrivacyScreen from "../screens/user/PrivacyScreen";
import DistanceSellingContractScreen from "../screens/user/DistanceSellingContractScreen.js"
import UsageTermsScreen from "../screens/user/UsageTermsScreen.js";
import FAQScreen from "../screens/user/FAQScreen";
import ContactScreen from "../screens/user/ContactScreen.js"
import CookiesScreen from "../screens/user/CookiesScreen.js";
import { View } from "react-native";
import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAuth } from "../context/AuthContext";

const Stack = createNativeStackNavigator();
const linking = {
  prefixes: [
    ["https://raftankitap.com", "http://localhost:19006"]
  ],
  config: {
    /* configuration for matching screens with paths */
  },
};
const NotFoundScreen = ({ route }) => {
  return <View>Sayfa bulunmadi: {JSON.stringify(route)}</View>;
}
const Routes = () => {
  const { userData, setUserData, clearUserData } = useAuth();

  // // check the userData and navigate to screens accordingly on initial render
  // useEffect(() => {
  //   const _checkTokenExpiration = async () => {
  //     try {
  //       const userDataJson = await AsyncStorage.getItem('userData');
  //       if (userDataJson) {
  //         const userData = JSON.parse(userDataJson);
  //         setUser(userData); // doesnt work
  //         console.log('userData loaded', userData);
  //       } else {
  //         // navigate to login page
  //         console.error('Error loading user data inside else:', userDataJson);
  //       }
  //     } catch (error) {
  //       // Handle error
  //       console.error('Error loading user data:', error);
  //     }
  //     // if (!userData) {
  //     //   navigationRef.current?.navigate("login")
  //     // }
  //   };
  //   _checkTokenExpiration();
  // }, []);

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator
        initialRouteName="splash"
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen name="landing" component={LandingScreen} />
        <Stack.Screen name="splash" component={SplashScreen} />
        <Stack.Screen name="login" component={LoginScreen} />
        <Stack.Screen name="signup" component={SignupScreen} />
        <Stack.Screen name="forgetpassword" component={ForgetPasswordScreen} />
        <Stack.Screen name="changepassword" component={ChangePasswordScreen} />
        <Stack.Screen name="myaccount" component={MyAccountScreen} />
        <Stack.Screen name="corporate" component={Corporate} />
        <Stack.Screen name="feedback" component={Feedback} />
        <Stack.Screen name="dispute" component={DisputeScreen} />
        <Stack.Screen name="about" component={AboutScreen} />
        <Stack.Screen name="return" component={ReturnTermsScreen} />
        <Stack.Screen name="terms" component={UsageTermsScreen} />
        <Stack.Screen name="cookies" component={CookiesScreen} />
        
        {/* <Stack.Screen name="mywishlist" component={MyWishlistScreen} /> */}
        {/* <Stack.Screen name="dashboard" component={DashboardScreen} /> */}
        <Stack.Screen name="addproduct" component={AddProductScreen} />
        <Stack.Screen name="viewmyproducts" component={ViewMyProductsScreen} />
        <Stack.Screen name="editproduct" component={EditProductScreen} />
        <Stack.Screen name="tabs" component={Tabs} />
        <Stack.Screen name="cart" component={CartScreen} />
        <Stack.Screen name="checkout" component={CheckoutScreen} />
        <Stack.Screen name="orderconfirm" component={OrderConfirmScreen} />
        <Stack.Screen name="productdetail" component={ProductDetailScreen} />
        <Stack.Screen name="viewsales" component={ViewSalesScreen} />
        <Stack.Screen name="bank" component={BankDetailsScreen} />
        <Stack.Screen name="viewoffersreceived" component={ViewOffersReceivedScreen} />
        <Stack.Screen name="viewofferssent" component={ViewOffersSentScreen} />
        <Stack.Screen
          name="viewsaledetails"
          component={ViewSaleDetailsScreen}
        />
        <Stack.Screen
          name="viewpurchasedetails"
          component={ViewPurchaseDetailsScreen}
        />
        <Stack.Screen name="viewpurchases" component={ViewPurchasesScreen} />
        <Stack.Screen name="privacy" component={PrivacyScreen} />
        <Stack.Screen name="distanceselling" component={DistanceSellingContractScreen} />
        <Stack.Screen name="faq" component={FAQScreen} />
        <Stack.Screen name="contact" component={ContactScreen} />
        {/* <Stack.Screen name="myseller" component={SellerScreen} /> */}
        {/* <Stack.Screen name="myorderdetail" component={MyOrderDetailScreen} /> */}
        {/* <Stack.Screen name="viewcategories" component={ViewCategoryScreen} />
        <Stack.Screen name="addcategories" component={AddCategoryScreen} />
        <Stack.Screen name="editcategories" component={EditCategoryScreen} /> */}
        {/* <Stack.Screen name="viewusers" component={ViewUsersScreen} /> */}
        {/* <Stack.Screen name="categories" component={CategoriesScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Routes;
