import {
    StyleSheet,
    Text,
    StatusBar,
    View,
    ScrollView,
    TouchableOpacity,
    RefreshControl,
    Alert,
    Image
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network, OFFER_STATUS } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import ProductList from "../../components/ProductList/ProductList";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import { useToast } from "react-native-toast-notifications";
import { useAuth } from "../../context/AuthContext";
import AskLogin from "../../components/AskLogin";
import { useIsFocused } from "@react-navigation/native";
const offers_sample = [{ offerAmount: 13, offeredQuantity: 2, product: { title: "test", quantity: 3, author: "re. e", description: "good book" }, offerSentBy: "test@gmail.com" }]
const ViewOffersReceivedScreen = ({ navigation, route }) => {
    const isFocused = useIsFocused();
    const { user, setUserData, clearUserData } = useAuth();
    console.log("user inside ViewOffersReceivedScreen", user)
    const toast = useToast();
    const [isloading, setIsloading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [label, setLabel] = useState("Yükleniyor...");
    const [offers, setOffers] = useState([]);

    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);

    var ProductListRequestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders
    };

    //method call on pull refresh
    const handleOnRefresh = () => {
        setRefreshing(true);
        fetchOffersReceived();
        setRefreshing(false);
    };

    //method to decline the specific offer
    const handleDeclineOffer = (id) => {

        setIsloading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        var declineRequestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(`${network.serverip}/offer/${id}/decline`, declineRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    fetchOffersReceived();
                    toast.show("Teklif başarıyla reddedildi", {
                        placement: "bottom",
                        type: "success_with_close_button"
                    });
                } else {
                    toast.show("Teklifi reddederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
                setIsloading(false);
            })
            .catch((error) => {
                setIsloading(false);
                toast.show("Teklifi reddederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
    };


    //method to decline the specific offer
    const handleAcceptOffer = (id) => {

        setIsloading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        var acceptRequestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(`${network.serverip}/offer/${id}/accept`, acceptRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    fetchOffersReceived();
                    toast.show("Teklif başarıyla kabul edildi ve alıcı haberdar edildi. Alıcının yeni fiyatla satın almasını bekleyin", {
                        placement: "bottom",
                        type: "success_with_close_button"
                    });
                } else {
                    toast.show("Teklifi reddederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
                setIsloading(false);
            })
            .catch((error) => {
                setIsloading(false);
                toast.show("Teklifi reddederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                    placement: "bottom",
                    type: "success_with_close_button"
                });
                console.log("error", error);
            });
    };

    //method for alert
    const showDeclineConfirmDialog = (id) => {
        return AlertPolyfill(
            "Teklifi Reddetmeyi Onayla",
            "Bu teklifi reddetmek istediğinizden emin misiniz? Teklif ettikten sonra kararınızı değiştirirseniz, yorumlara gidin ve alıcıyla tekrar iletişime geçin.",
            [
                {
                    text: "Teklifi Reddet",
                    onPress: () => {
                        handleDeclineOffer(id);
                    },
                },
                {
                    text: "Vazgeç",
                },
            ]
        );
    };

    //method for alert
    const showAcceptConfirmDialog = (id) => {
        return AlertPolyfill(
            "Teklifi Kabul Etmeyi Onayla",
            "Bu teklifi kabul edince alıcı, yeni fiyattan haberdar edilecek ve kredi kartı bilgileri istenilecek. Teklifi kabul etmek, bu ürünü şimdi satıyorsunuz demek değildir. Teklif verenin, ürünü satın alması gerek. Eğer alıcı satın alırsa, siparişi 'Siparişlerim' sayfasından takip edebilirsiniz. Teklifi kabul etmek istediğinizden emin misiniz?",
            [
                {
                    text: "Teklifi Kabul Et",
                    onPress: () => {
                        handleAcceptOffer(id);
                    },
                },
                {
                    text: "Vazgeç",
                },
            ]
        );
    };

    //method the fetch the product data from server using API call
    const fetchOffersReceived = () => {
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);

        var fetchOffersReceivedRequestOptions = {
            method: "GET",
            headers: myHeaders
        };
        setIsloading(true);
        fetch(`${network.serverip}/offers/${user?._id}/received`, fetchOffersReceivedRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setOffers(result.data);
                    setIsloading(false);
                } else {
                    // toast.show(messages.needLoginMessage, {
                    //     placement: "bottom",
                    //     type: "danger_with_close_button"
                    // });
                    setIsloading(false);
                }
            })
            .catch((error) => {
                toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                setIsloading(false);
            });

        setIsloading(false);
    };

    //method to filer the orders for by title [search bar]
    const filter = () => {
        const keyword = filterItem;
        if (keyword !== "") {
            const results = products?.filter((product) => {
                return product?.title.toLowerCase().includes(keyword.toLowerCase());
            });
            setFoundItems(results);
        } else {
            setFoundItems(products);
        }
    };

    // //filter the data whenever filteritem value change
    // useEffect(() => {
    //   // filter();
    // }, [filterItem]);

    //fetch the categories on initial render
    useEffect(() => {
        // if (user) {
        fetchOffersReceived();
        // }
    }, [isFocused, user]);

    return (
        <View style={styles.container}>
            <ProgressDialog visible={isloading} label={label} />
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            <View style={styles.TopBarContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate("tabs", { screen: "sell" });
                    }}
                >
                    <Ionicons
                        name="arrow-back-circle-outline"
                        size={30}
                        color={colors.muted}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.screenNameContainer}>
                <View>
                    <Text style={styles.screenNameText}>Aldığım Teklifler</Text>
                </View>
                <View>
                    <Text style={styles.screenNameParagraph}>- Bazı kullanıcılar, ürünlerinizi direkt satın almaz. Onun yerine size teklif verirler. </Text>
                    <Text style={styles.screenNameParagraph}>- Teklif veren kullanıcılar genellikle gerçekten kitabınızı almak isterler.</Text>
                    <Text style={styles.screenNameParagraph}>- Bu teklifleri kabul etmeyi göz önünde bulundurun. Aldığınız teklifleri burada kabul veya reddedebilirsiniz.</Text>
                </View>
            </View>
            <ScrollView
                style={{ flex: 1, width: "100%" }}
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={handleOnRefresh} />
                }
            >
                {offers.length == 0 ? (
                    <>
                        <View style={styles.noOffersContainer}>
                            <Text style={styles.noOfferViewText}>Hiçbir Teklif Almadınız</Text>
                        </View>
                        {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
                    </>
                ) : (
                    offers.map((offer, index) => {
                        {/** Received offers can be pending, accepted, declined and cancelled. */ }
                        var styleForStatus = styles.pendingStatus;
                        if (offer.status == OFFER_STATUS.ACCEPTED) {
                            styleForStatus = styles.acceptedStatus;
                        } else if (offer.status == OFFER_STATUS.DECLINED || offer.status == OFFER_STATUS.CANCELED) {
                            styleForStatus = styles.declinedStatus;
                        } else if (offer.status == OFFER_STATUS.PENDING) {
                            styleForStatus = styles.pendingStatus;
                        }
                        return (
                            <View key={index} style={styles.offerContainer}>
                                <View style={styles.innerOfferContainer}>
                                    <View>
                                        <Image source={{ uri: `${network.serverip}/uploads/${offer.productId.filename}` }} style={styles.productImage} />
                                    </View>
                                    <View style={styles.bidItem}>
                                        <Text style={styles.productTitle}>Teklif Edilen Ürün: {offer.productId.title}</Text>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Teklif Fiyatı: </Text>
                                            <Text style={styles.priceText}>{offer.offerAmount}₺</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Teklif Veren Kişi: </Text>
                                            <Text>{offer.offererId.username}</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Teklif Verenin Almak İstediği Adet: </Text>
                                            <Text>{offer.offerQuantity} tane</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Tekliften Önceki Fiyat:  </Text>
                                            <Text>{offer.productId.price} ₺</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Teklif Durumu: </Text>
                                            <Text style={[styleForStatus, styles.offerStatusText]}>{offer.status}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.buttonContainer}>
                                    {offer.status === OFFER_STATUS.PENDING ? (<>
                                        <TouchableOpacity style={styles.acceptButton} onPress={() => showAcceptConfirmDialog(offer._id)}>
                                            <Text>KABUL ET</Text>
                                            <AntDesign name="checkcircleo" size={24} color="#008000" />
                                        </TouchableOpacity>
                                        <TouchableOpacity style={styles.declineButton} onPress={() => showDeclineConfirmDialog(offer._id)}>
                                            <Text>REDDET</Text>
                                            <AntDesign name="closecircleo" size={30} color="red" />
                                        </TouchableOpacity>
                                    </>
                                    ) : (
                                        <>
                                            <Text style={styles.offerStatusText}>TEKLİF KARARI ÇOKTAN VERİLDİ</Text>
                                        </>
                                    )}
                                </View>
                            </View>
                        );
                    }))}
            </ScrollView>
        </View>
    )
};


export default ViewOffersReceivedScreen;

const styles = StyleSheet.create({
    offerStatusText: {
        fontSize: 16,
        fontWeight: "bold"
    },
    defaultStatus: {
        color: "black",
        backgroundColor: "yellow"
    },
    acceptedStatus: {
        color: "green",
    },
    declinedStatus: {
        color: "red",
    },
    pendingStatus: {
        color: "blue",
        backgroundColor: "yellow"
    },
    acceptButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        padding: 10,
        margin: 20,
        borderRadius: 8,
        borderColor: "black",
        borderWidth: 3,
        alignItems: "center"
    },
    declineButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        padding: 10,
        margin: 20,
        borderRadius: 8,
        borderColor: "black",
        borderWidth: 3,
        alignItems: "center"
    },
    priceText: {
        fontSize: 15,
        fontWeight: "bold",
        color: colors.primary,
    },
    productTitle: {
        fontSize: 14,
        fontWeight: "bold",
    },
    offerInfoItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    noOffersContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    noOfferViewText: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginTop: 5,
        color: "black",
        padding: 2,
        backgroundColor: "yellow",
    },
    productName: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginBottom: 8,
    },
    bidAmount: {
        fontSize: 16,
        marginBottom: 8,
        color: "red"
    },
    originalPrice: {
        fontSize: 20, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        color: 'blue', // Customize the color as desired
    },
    offerInfoText: {
        fontSize: 13,
        fontWeight: "500",
        color: colors.muted,
    },
    offerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        backgroundColor: colors.white,
        padding: 5,
        borderRadius: 5,
        marginTop: 10,
    },
    innerOfferContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    container: {
        flexDirecion: "row",
        backgroundColor: colors.light,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        flex: 1,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    formContainer: {
        flex: 2,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        width: "100%",
        flexDirecion: "row",
        padding: 5,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 10
    },
    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
    screenNameParagraph: {
        marginTop: 5,
        fontSize: 15,
    },
});
