import {
  StyleSheet,
  Text,
  Image,
  StatusBar,
  View,
  KeyboardAvoidingView,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import ProgressDialog from "react-native-progress-dialog";
import React, { useState } from "react";
import { colors, messages, network } from "../../constants";
import CustomInput from "../../components/CustomInput";
import header_logo from "../../assets/logo/logo.png";
import CustomButton from "../../components/CustomButton";
import { Ionicons } from "@expo/vector-icons";
import { useToast } from "react-native-toast-notifications";
const SignupScreen = ({ navigation }) => {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isloading, setIsloading] = useState(false);

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    email: email,
    password: password,
    username: username,
    userType: "USER",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  //method to post the user data to server for user signup using API call
  const signUpHandle = () => {
    if (email == "") {
      toast.show("Lütfen emailinizi girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (username == "") {
      toast.show("Lütfen bir kullanıcı adı girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (password == "") {
      toast.show("Lütfen şifre girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (!email.includes("@")) {
      toast.show("Email geçerli değil", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (password.length < 6) {
      toast.show("Şifreniz en az 6 karakter olmalıdır", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (password != confirmPassword) {
      toast.show("Şifre tekrarınız şifrenizle aynı değil", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    setIsloading(true);
    fetch(network.serverip + "/register", requestOptions) // API call
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        if (result.data["email"] == email) {
          toast.show("Hesabınız başarıyla yaratıldı", {
            placement: "bottom",
            type: "success_with_close_button"
          });
          setIsloading(false);
          navigation.navigate("login");
        }
      })
      .catch((error) => {
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
        setIsloading(false);
      });
    setIsloading(false);
  };
  return (
    // <InternetConnectionAlert
    //   onChange={(connectionState) => {
    //     console.log("Connection State: ", connectionState);
    //   }}
    // >
      <KeyboardAvoidingView style={styles.container}>
        <ProgressDialog visible={isloading} label={"Yükleniyor..."} />
        <StatusBar backgroundColor={colors.primary}></StatusBar>
        <View style={styles.TopBarContainer}>
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate("landing");
              }
            }}
          >
            <Ionicons
              name="arrow-back-circle-outline"
              size={30}
              color={colors.muted}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate("landing");
              }
            }}
          >
            <Ionicons
              name="close-outline"
              size={30}
              color={colors.muted}
            />
          </TouchableOpacity>
        </View>
        <ScrollView style={{ flex: 1, width: "100%" }}>
          <View style={styles.welconeContainer}>
            <Image style={styles.logo} source={header_logo} />
          </View>
          <View style={styles.screenNameContainer}>
            <View>
              <Text style={styles.screenNameText}>Üye Ol</Text>
            </View>
          </View>
          <View style={styles.formContainer}>
            <CustomInput
              value={username}
              setValue={setUsername}
              placeholder={"Kullanıcı Adı"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
            <CustomInput
              value={email}
              setValue={setEmail}
              placeholder={"E-mail"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
            <CustomInput
              value={password}
              setValue={setPassword}
              secureTextEntry={true}
              placeholder={"Şifre"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
            <CustomInput
              value={confirmPassword}
              setValue={setConfirmPassword}
              secureTextEntry={true}
              placeholder={"Şifreyi tekrarla"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
          </View>
        </ScrollView>
        <View style={styles.buttomContainer}>
          <CustomButton isAuthPage={true} text={"KAYDET"} onPress={signUpHandle} />
        </View>
        <View style={styles.bottomContainer}>
          <Text>Hesabım zaten var?</Text>
          <Text
            onPress={() => navigation.navigate("login")}
            style={styles.signupText}
          >
            Giriş yap
          </Text>
        </View>
      </KeyboardAvoidingView>
    // </InternetConnectionAlert>
  );
};

export default SignupScreen;

const styles = StyleSheet.create({
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  welconeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
  },
  formContainer: {
    flex: 2,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },
  logo: {
    resizeMode: "contain",
    width: 80,
  },
  forgetPasswordContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  ForgetText: {
    fontSize: 15,
    fontWeight: "600",
  },
  buttomContainer: {
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  signupText: {
    marginLeft: 2,
    color: colors.primary,
    fontSize: 15,
    fontWeight: "600",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
});
