import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  Image,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { network, colors, messages } from "../../constants";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import ProgressDialog from "react-native-progress-dialog";
import * as ImagePicker from "expo-image-picker";
import { AntDesign } from "@expo/vector-icons";
import { useToast } from "react-native-toast-notifications";
import DropDownPicker from "react-native-dropdown-picker";
import Base64 from "../../components/Base64";
import { useAuth } from "../../context/AuthContext";
const FEEDBACK_TEMPLATE = `
-[ ] **Açıklama Belirtilen Gibi Değil (ABGD):** Bu, bir alıcının, satıcının sağladığı açıklamadan önemli ölçüde farklı bir kitap aldığında meydana gelir. Örneğin, kitabın durumu açıklamada belirtilenden kötüyse veya farklı bir baskı ise.

-[ ] **Teslimat Sorunu:** Bu anlaşmazlık, bir alıcının belirtilen süre içinde veya hiçbir şekilde kitap almadığında ortaya çıkar.

-[ ] **Ödeme Sorunları:** Ödeme sorunlarıyla ilgili anlaşmazlıklar, yetkisiz işlemler, başarısız ödemeler veya fazla ücretlendirmeler gibi sorunları içerebilir.

-[ ] **İptal Anlaşmazlıkları:** Alıcının veya satıcının bir işlemi iptal etmesi durumunda, iade miktarları veya iptal ücretleri gibi konularda anlaşmazlıklar ortaya çıkabilir.

-[ ] **İade Talepleri:** Alıcılar, kitaplarının hasar gördüğü veya fikirlerini değiştirdikleri gibi farklı nedenlerle iade taleplerinde bulunabilirler. İade süreci hakkında anlaşmazlıklar, iade işlemi veya koşulları hakkında anlaşmazlıklar nedeniyle meydana gelebilir.

-[ ] **Kargo Anlaşmazlıkları:** Kargo ile ilgili sorunlar, gecikmiş teslimatlar, kayıp paketler veya transit sırasında hasar gören ürünler gibi konuları içerir.

-[ ] **Kalite Anlaşmazlıkları:** Alıcılar, kitapların kalite veya sahnelendikleri şekil açısından beklentilerini karşılamadıklarında bu tür anlaşmazlıklar meydana gelebilir.

-[ ] **İade İle İlgili Anlaşmazlıklar:** İade miktarları, işleme süreleri veya iade için uygunluk konusunda anlaşmazlıklar.

-[ ] **Geribildirim ve Derecelendirme Anlaşmazlıkları:** Alıcılar veya satıcılar tarafından bırakılan derecelendirme, inceleme veya geri bildirimle ilgili anlaşmazlıklar.

-[ ] **Satıcı Performansı Anlaşmazlıkları:** Bir satıcının sürekli olarak yükümlülüklerini yerine getiremediği durumlarda, platform üzerindeki performansları ile ilgili anlaşmazlıklar meydana gelebilir.

Bu tür anlaşmazlıkları platformunuzdaki sorunları dostane bir şekilde çözmek için elimizden gelenin en iyisini yapıyoruz. Bize güvendiğiniz için teşekkür ederiz
`
const problem_types = { title: "Urun gelmedi" }
const DisputeScreen = ({ navigation, route }) => {
  const { disputedOrderNumber, product } = route.params;
  const {user, setUserData, clearUserData} = useAuth();
  console.log("user", user, "disputedOrderNumber", disputedOrderNumber, "product", product)
  const [feedbackContent, setFeedbackContent] = useState(FEEDBACK_TEMPLATE);
  const [feedbackTitle, setFeedbackTitle] = useState("Hata, Öneri veya Sorun Bildirmek İstiyorum")
  const [isloading, setIsloading] = useState(false);
  const [image, setImage] = useState("");
  const toast = useToast();

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }
  //Method for selecting the image from device gallery
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
    });

    if (!result.cancelled) {
      console.log("result", result);
      setImage(result.uri);
    }
  };

  // method to send feedback via REST API
  const sendDisputeHandle = async () => {
    if (feedbackTitle === "") {
      toast.show("Lütfen baslığı boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      })

    } else if (feedbackContent === "") {
      toast.show("Lütfen metni boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      })
    } else {

      var myHeaders = new Headers();
      myHeaders.append("x-auth-token", user?.token);
      var formdata = new FormData();
      try {
        if (image != "") {
          // converts to blob. Source: https://stackoverflow.com/a/53454470
          const blob = dataURItoBlob(image);
          const uploadedImage = new File([blob], "upload.jpg", {
            type: 'image/jpeg'
          });
          formdata.append("image", uploadedImage);
        }

        formdata.append("feedbackContent", feedbackContent);
        formdata.append("feedbackTitle", feedbackTitle);
        formdata.append("productId", product._id)
        formdata.append("disputedOrderNumber", disputedOrderNumber)

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata
        };

        fetch(
          network.serverip + "/dispute",
          requestOptions
        ) // API call
          .then((response) => response.json())
          .then((result) => {
            if (result.success) {
              toast.show("Form başarıyla gönderildi.", { placement: "bottom", type: "success_with_close_button" });
              setIsloading(false);
            } else {
              toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
              setIsloading(false);
            }
          })
          .catch((error) => {
            toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
            console.log("error", error.message);
            setIsloading(false);
          });
      } catch (error) {
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
        console.log("error", error.message);
        setIsloading(false);
      }
    }

  };
  return (
    <View style={styles.container}>
      <ProgressDialog visible={isloading} label={"Yükleniyor..."} />
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack(); // leave goBack since we come here from viewpurchasedetails and we dont have the clicked purchase detail
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Siparişle İlgili Problem Bildir</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>- Eğer verdiğiniz ürünle ilgili herhangi bir sorun yaşarsanız, lütfen aşağıdaki formu kullanarak bize bildirin. </Text>
          <Text style={styles.screenNameParagraph}>- Sorununuzu en kısa sürede çözmek için elimizden geleni yapacağız. </Text>
          <Text style={styles.screenNameParagraph}>- Size yardımcı olabilmemiz için lütfen sorununuzu açıklayan bilgileri doldurun. (Problem türü, detaylı bilgiler ve eklemek istediginiz fotoğraf)</Text>
          <Text style={styles.screenNameParagraph}>- Size kolaylık olsun diye, çeşitli problem türlerini açıklayan bir taslak ekledik. Size uyan kutucuğa çarpı işareti koyarak problem türünüzü seçebilirsiniz.</Text>
          <Text style={styles.screenNameParagraph}>- Sorununuzu ilettikten sonra müşteri hizmetleri ekibimiz en kısa sürede sizinle iletişime geçecektir.</Text>
          <Text style={styles.screenNameParagraph}>- Teşekkür ederiz.</Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <View style={styles.imageContainer}>
          <Text style={{ color: colors.muted }}>Ek Fotoğraf Buraya Ekleyebilirsiniz (Zorunlu Değil)</Text>
          {image ? (
            <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
              <Image
                source={{ uri: image }}
                style={{ width: 200, height: 200 }}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
              <AntDesign name="pluscircle" size={50} color={colors.muted} />
            </TouchableOpacity>
          )}
        </View>
        <Text style={{ color: colors.muted }}>Başlık</Text>
        <CustomInput
          value={feedbackTitle}
          setValue={setFeedbackTitle}
          placeholder={"Bir başlık girin"}
        />
        <Text style={{ color: colors.muted }}>Problemin Türü ve Detaylar</Text>
        <CustomInput
          value={feedbackContent}
          multiline
          editable
          numberOfLines={12}
          maxLength={10000}
          setValue={setFeedbackContent}
          height={350}
        />
      </View>
      <CustomButton
        text={"GÖNDER"}
        onPress={(e) => sendDisputeHandle(e) && setIsloading(true)}
        radius={5}
      />
    </View>
  );
};

export default DisputeScreen;

const styles = StyleSheet.create({
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  formContainer: {
    marginTop: 10,
    marginBottom: 20,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    minHeight: 100,
    flexDirecion: "row",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: 250,
    backgroundColor: colors.white,
    borderRadius: 10,
    elevation: 5,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 5
  },
  imageHolder: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.light,
    borderRadius: 10,
    elevation: 5,
  },
});
