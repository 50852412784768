import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import React from "react";
import { colors } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import { useSelector, useDispatch } from "react-redux";

const CartIconAndStatus = ({ navigation }) => {
    const cartproduct = useSelector((state) => state.product);
    const totalQuantity = cartproduct.reduce((sum, book) => sum + book.quantity, 0);
    return (
        <TouchableOpacity
            style={styles.cartIconContainer}
            onPress={() => navigation.navigate("cart")}
        >
                <Text style={styles.cartItemCountText}>SEPETİNİZ</Text>
            <View style={styles.iconContainer}>
                <Ionicons style={{marginRight: '5px'}} name='cart' size={24} color={colors.primary} />
                <Text style={styles.cartItemCountText}>{totalQuantity}</Text>
            </View>
        </TouchableOpacity>
    );
};

export default CartIconAndStatus;

const styles = StyleSheet.create({
    cartIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.bisque,
        padding: 5,
        borderRadius: 8
    },
    iconContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 3
    },
    cartItemCountText: {
        fontWeight: "bold",
        fontSize: 18,
      },
});
