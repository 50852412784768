import { StyleSheet, Text, TouchableOpacity, View, Image, TextInput } from "react-native";
import React from "react";
import { colors, network } from "../../constants";
import { Ionicons } from "@expo/vector-icons";

const ProductCard = ({
  name,
  price,
  image,
  onPress,
  onPressSecondary,
  cardSize,
  quantity
}) => {
  return (
    <TouchableOpacity
      style={[styles.container]}
      onPress={onPress}
    >
      <View style={styles.imageContainer}>
        <Image source={{ uri: image }} style={styles.productImage} />
      </View>
      <View style={styles.infoContainer}>
          <Text style={styles.titleText}>{name}</Text>
        {/* <TextInput value={name} multiline={true} editable={false} readOnly textAlignVertical="top" style={styles.secondaryTextSm}></TextInput> */}
        <View style={styles.sameRow}>
          <Text style={styles.primaryTextSm}>{price}₺</Text>
          {quantity > 0 ? (
            <TouchableOpacity
              style={styles.iconContainer}
              onPress={onPressSecondary}>
              <Ionicons name="cart" size={20} color="white" />
            </TouchableOpacity>
          ) : (
            <Text style={styles.outOfStockContainerText}>Stokta Kalmadı</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ProductCard;

const styles = StyleSheet.create({
  sameRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  outOfStockContainer: {
    width: 30,
    height: 'fit-content',
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  outOfStockContainerText: {
    fontSize: 12,
    fontWeight: "bold",
    color: colors.danger,
  },
  titleText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  container: {
    backgroundColor: colors.white,
    width: 250,
    height: 225,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 5,
    elevation: 5,
  },
  imageContainer: {
    backgroundColor: colors.light,
    width: "100%",
    height: 140,
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 5,
    paddingBottom: 0,
  },
  productImage: {
    height: 140,
    width: 160,
  },
  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  secondaryTextSm: {
    fontSize: 16,
    fontWeight: "bold",
  },
  primaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
    color: colors.primary,
  },
  iconContainer: {
    backgroundColor: colors.primary,
    width: 30,
    height: 30,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconContainerDisable: {
    backgroundColor: colors.muted,
    width: 30,
    height: 30,
    borderRadius: 5,
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
  },
});
