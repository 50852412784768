const messages = {
  genericErrorMessage: "Bir hata oluştu. Lütfen daha sonra tekrar deneyin.",
  needLoginMessage: "Seansınızın süresi bitti veya üye değilsiniz. Lütfen üye olun veya giriş yapın "
};
export default messages;

export const ORDER_STATUS = {
  PENDING: "KARGO İÇİN HAZIRLANIYOR", // PENDING
  SHIPPED: "KARGOYA VERİLDİ", // SHIPPED
  DELIVERED: "TESLİM EDİLDİ" // DELIVERED

}
export const OFFER_STATUS = {
  ACCEPTED: "KABUL EDİLDİ",
  PENDING: "KARAR BEKLENİYOR",
  DECLINED: "REDDEDİLDİ",
  CANCELED: "TEKLİF VEREN TARAFINDAN İPTAL EDİLDİ"
}