import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";
import { colors, network } from "../../constants";

import Swipeable from "react-native-gesture-handler/Swipeable";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";

const CartProductList = ({
    image,
    title,
    price,
    quantity = 1,
    onPressIncrement,
    onPressDecrement,
    handleDelete,
}) => {
    const rightSwipe = () => {
        return (
            <View style={styles.deleteButtonContainer}>
                <TouchableOpacity onPress={handleDelete}>
                    <MaterialCommunityIcons
                        name="delete"
                        size={25}
                        color={colors.primary}
                    />
                </TouchableOpacity>
            </View>
        );
    };
    return (
        <GestureHandlerRootView>
            <View style={styles.containerOuter}>
                <Swipeable renderRightActions={rightSwipe}>
                    <View style={styles.container}>
                        <View style={styles.imageContainer}>
                            <Image source={{ uri: image }} style={styles.productImage} />
                        </View>
                        <View style={styles.productInfoContainer}>
                            <Text style={styles.productTitle}>{title}</Text>
                            <View style={styles.productListBottomContainer}>
                                <Text style={styles.productPrice}>{price * quantity} ₺</Text>
                            </View>
                        </View>

                        <View style={styles.counter}>
                            <TouchableOpacity
                                style={styles.counterButtonContainer}
                                onPress={onPressDecrement}
                            >
                                {/* <Text style={styles.counterButtonText}>-</Text> */}
                                <Ionicons name="remove-circle-outline" size={30}></Ionicons>
                            </TouchableOpacity>
                            <Text style={styles.counterCountText}>{quantity}</Text>
                            <TouchableOpacity
                                style={styles.counterButtonContainer}
                                onPress={onPressIncrement}
                            >
                                <Ionicons name="add-circle-outline" size={30}></Ionicons>
                                {/* <Text style={styles.counterButtonText}>+</Text> */}
                            </TouchableOpacity>
                        </View>


                    </View>
                </Swipeable>
            </View>
        </GestureHandlerRootView>
    );
};

export default CartProductList;

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: colors.white,
        height: 120,
        borderRadius: 15,
        width: "100%",
        // padding: 10,
        marginBottom: 10,
        elevation: 2,
    },
    productImage: {
        width: 100,
        height: 100,
    },
    imageContainer: {
        backgroundColor: colors.light,
        borderRadius: 10,
    },
    productInfoContainer: {
        padding: 10,
        height: "100%",
        marginLeft: 0,
    },
    productTitle: {
        fontSize: 18,
        fontWeight: "bold",
        color: colors.dark,
    },
    productQuantitySm: {
        fontSize: 15,
        fontWeight: "bold",
        color: colors.muted,
    },
    productPrice: {
        fontWeight: "bold",
        fontSize: 15,
        color: colors.primary,
    },
    deleteButtonContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.primary_light,
        borderTopEndRadius: 15,
        borderBottomRightRadius: 15,
        marginBottom: 10,
        width: 70,
    },
    productListBottomContainer: {
        width: "auto",
        paddingRight: 10,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    counter: {
        backgroundColor: colors.white,
        width: 150,
        marginLeft: 20,
        padding: 5,
        borderRadius: 5,
        borderBottomRightRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 5,
        marginLeft: 'auto'
    },
    counterButtonContainer: {
        display: "flex",
        width: 30,
        height: 30,
        marginLeft: 10,
        marginRight: 10,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 15,
        elevation: 2,
    },
    counterButtonText: {
        fontSize: 20,
        fontWeight: "bold",
        color: colors.white,
    },
    counterCountText: {
        fontSize: 20,
        fontWeight: "bold",
    },
});
