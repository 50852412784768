import { StyleSheet, Text, View, Image } from "react-native";
import React from "react";
import { colors, formatDateStringToHumanReadable } from "../../constants";

const BasicProductList = ({ imageUri, quantity, title, price, description, status, seller, createdAt=null, shippedOn=null, deliveredOn=null }) => {
  return (
    <View style={styles.container}>
      <View style={styles.innerContainer}>
        <View style={styles.IconContainer}>
          <View style={styles.imageContainer}>
            <Image source={{ uri: imageUri }} style={styles.productImage} />
          </View>
        </View>
        <View style={styles.productInfoContainer}>
          <Text style={styles.secondaryText}>{title}</Text>
          <Text style={styles.secondaryText}>{description.length > 20 ? `${description.slice(0, 20)}...` : description}</Text>
          {seller && <Text style={styles.secondaryText}>Satıcı: {seller}</Text>}
        </View>
      </View>
      <View>
        <Text style={styles.primaryText}>{price}₺</Text>
        <Text style={styles.primaryText}>Tane: {quantity}</Text>
        {status && <Text style={styles.primaryText}>{status}</Text>}
        {/* <Text style={styles.secondarytextSm}>
          Sipariş Edildi : {formatDateStringToHumanReadable(createdAt)}
        </Text>
        <Text style={styles.secondarytextSm}>
          Postaya Verildi: {formatDateStringToHumanReadable(shippedOn)}
        </Text>
        <Text style={styles.secondarytextSm}>
          Teslim Edildi: {formatDateStringToHumanReadable(deliveredOn)}
        </Text> */}
      </View>
    </View>
  );
};

export default BasicProductList;

const styles = StyleSheet.create({
  secondarytextSm: {
    color: colors.muted,
    fontSize: 13,
  },
  container: {
    flexWrap: 'wrap',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: colors.white,
    padding: 5
  },
  innerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  productInfoContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 10,
  },
  productImage: {
    height: 40,
    width: 40,
    borderRadius: 10,
  },
  IconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.light,
    height: 40,
    width: 40,
    borderRadius: 5,
  },
  primaryText: {
    fontSize: 15,
    fontWeight: "600",
    color: colors.primary,
  },
  secondaryText: {
    fontSize: 15,
    fontWeight: "600",
  },
});
