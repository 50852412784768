import {
  StyleSheet,
  Text,
  StatusBar,
  View,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network, formatDateStringToHumanReadable } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import { useToast } from "react-native-toast-notifications";
import BasicProductList from "../../components/BasicProductList";
import { useAuth } from "../../context/AuthContext";
import AskLogin from "../../components/AskLogin";
import { useIsFocused } from '@react-navigation/native';
const ViewPurchasesScreen = ({ navigation, route }) => {
  const { user, setUserData, clearUserData } = useAuth();
  const isFocused = useIsFocused(); 
  console.log("user from useAuth", user);
  const [isloading, setIsloading] = useState(false);
  const [refeshing, setRefreshing] = useState(false);
  const [foundItems, setFoundItems] = useState([])
  const [label, setLabel] = useState("Yükleniyor...");
  const [purchases, setPurchases] = useState([]);
  const [filterItem, setFilterItem] = useState("");
  const toast = useToast()

  //method call on pull refresh
  const handleOnRefresh = () => {
    setRefreshing(true);
    fetchPurchases();
    setRefreshing(false);
  };

  const dateFormat = (datex) => {
    let t = new Date(datex);
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    const hours = ("0" + t.getHours()).slice(-2);
    const minutes = ("0" + t.getMinutes()).slice(-2);
    const seconds = ("0" + t.getSeconds()).slice(-2);
    const newDate = `${date}-${month}-${year}`;

    return newDate;
  };

  //method to navigate to order detail screen of specific order
  const handlePurchaseDetails = (item) => {
    navigation.navigate("viewpurchasedetails", {
      purchaseDetail: item
    });
  };

  //method the fetch the order data from server using API call
  const fetchPurchases = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsloading(true)
    fetch(`${network.serverip}/${user?._id}/purchases`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setPurchases(result.data);
          console.log("setPurchase", result.data)
        } else {
          // toast.show(messages.needLoginMessage, { placement: "bottom", type: "danger_with_close_button" });
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
        console.log("error", error);
      });
      setIsloading(false);
  };

  //method to filer the purchase for by title [search bar]
  const filter = () => {
    const keyword = filterItem;
    if (keyword !== "") {
      const results = purchases?.filter((item) => {
        return item?.orderId.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundItems(results);
    } else {
      setFoundItems(purchases);
    }
  };
  //filter the data whenever filteritem value change
  useEffect(() => {
    filter();
  }, [filterItem]);

  //fetch the purchases on initial render
  useEffect(() => {
    setIsloading(true);
    // if (user) {
      fetchPurchases();
    // }
  }, [isFocused, user]);

  return (
    <View style={styles.container}>
      <ProgressDialog visible={isloading} label={label} />
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("tabs", { screen: "profile" });
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Sipariş Ettiğim Kitaplar</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>Sipariş Ettiğiniz Kitapları Burada Görebilirsiniz.</Text>
        </View>
      </View>
      {/* <CustomInput
        radius={5}
        placeholder={"Ara..."}
        value={filterItem}
        setValue={setFilterItem}
      /> */}
      <ScrollView
        style={{ flex: 1, width: "100%", padding: 2 }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refeshing} onRefresh={handleOnRefresh} />
        }
      >
        {/* My purchases START */}
        {purchases.length == 0 ? (
          <>
            <View style={styles.noPurchasesContainer}>
              <Text style={styles.noPurchasesViewText}>
                Hiçbir kitap satın almadınız.
              </Text>
            </View>
            {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
            <View style={styles.emptyViewForOrders}></View>
          </>
        ) : (
          purchases.map((purchase, index) => {
            return (
              <View key={index} style={styles.purchaseContainer}>
                <View>
                  <View style={styles.innerRow}>
                    <Text style={styles.primaryText}>Sipariş # {purchase._id}</Text>
                    <View style={styles.timeDateContainer}>
                      <Text style={styles.secondaryTextSm}>
                        Sipariş Edildi : {formatDateStringToHumanReadable(purchase?.createdAt)}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.secondaryText}>Toplam Tutar: {purchase.totalAmount}$</Text>
                  <Text style={styles.secondaryText}>Siparişteki Ürün Miktarı : {purchase.items.reduce((accumulator, item) => item.quantity + accumulator, 0)}</Text>
                </View>
                <View style={styles.itemsContainer}>
                  {purchase.items.map((item, index) => {
                    return (
                      <View key={index} style={styles.innerRow}>
                        <BasicProductList seller={item?.sellerUser?.username} quantity={item.quantity} description={item.product.description} imageUri={`${network.serverip}/uploads/${item.product.filename}`} title={item.product.title} price={item.price} status={item.status} />
                      </View>
                    )
                  })}
                </View>
                <View style={styles.innerRow}>
                  <TouchableOpacity style={styles.detailButton} onPress={() => handlePurchaseDetails(purchase)}>
                    <Text>Detaylar</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )
          })
        )}
        {/* My purchases END */}
      </ScrollView>
    </View>
  );
};

export default ViewPurchasesScreen;

const styles = StyleSheet.create({
  noPurchasesContainer:{
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  noPurchasesViewText: {
      fontSize: 18, // Adjust the font size as needed
      fontWeight: 'bold', // Bold text
      marginTop: 5,
      color: "black",
      padding: 2,
      backgroundColor: "yellow",
  },
  secondaryTextSm: {
    fontSize: 11,
    color: colors.muted,
    fontWeight: "bold",
  },
  secondaryText: {
    fontSize: 14,
    color: colors.muted,
    fontWeight: "bold",
  },
  timeDateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  innerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: 'wrap',
    alignItems: "center",
    width: "100%",
  },
  primaryText: {
    fontSize: 15,
    color: colors.dark,
    fontWeight: "bold",
  },
  purchaseContainer: {
    display: "flex",
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    elevation: 1,
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "auto",
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    elevation: 1,
  },
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formContainer: {
    flex: 2,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },

  buttomContainer: {
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  detailButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    borderWidth: 1,
    padding: 5,
    borderColor: colors.muted,
    color: colors.muted,
    width: 100,
  },
});
