import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const Ratings = ({ showInitialRating = true, customStyle, labelText, readOnly, onPress, initialRating, onRatingChange, initialRatingsCount, updatedRating=0, updatedRatingsCount=0 }) => {
  const [rating, setRating] = useState(initialRating);
  const [ratingsCount, setRatingsCount] = useState(initialRatingsCount)
  const [selectedRating, setSelectedRating] = useState();
  useEffect(() => {
    setRating(initialRating);
    setRatingsCount(initialRatingsCount);
  }, [initialRating, initialRatingsCount]);


  useEffect(() => {
    if (updatedRating){
      setRating(updatedRating);
    }
    if (updatedRatingsCount){
      setRatingsCount(updatedRatingsCount);
    }
  }, [updatedRating, updatedRatingsCount]);

  const handleStarPress = (newRating) => {
    if (readOnly) {
      return null;
    }
    onRatingChange(newRating);
    setSelectedRating(newRating);
    setRatingsCount(updatedRatingsCount);
    setRating(updatedRating)
  };

  return (
    <View style={[styles.container, customStyle]}>
      <Text style={styles.label}>{labelText}: </Text>
      <View style={styles.starsContainer}>
        {[1, 2, 3, 4, 5].map((star) => (
          <TouchableOpacity
            disabled={readOnly}
            key={star}
            onPress={() => handleStarPress(star)}
            style={styles.starContainer}
          >
            <Text
              style={[
                styles.star,
                star <= rating ? styles.starFilled : null,
              ]}
            >
              ★
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      {showInitialRating ? <Text style={styles.ratingInfo}>Ortalama Oy: {rating.toFixed(1)} ({ratingsCount} Oylama)</Text> : <></>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: 'center',
  },
  label: {
    marginRight: 2,
    fontSize: 15, fontWeight: "bold",
  },
  starsContainer: {
    flexDirection: 'row',
  },
  starContainer: {
    marginRight: 5,
  },
  star: {
    color: 'gray',
    fontSize: 24,
  },
  starFilled: {
    color: 'gold',
  },
  ratingInfo: {
    opacity: 0.5,
    fontWeight: 'bold',
  },
});

export default Ratings;
