import { StyleSheet, Image, View } from "react-native";
import React, { useEffect } from "react";
import { colors, messages } from "../../constants";
import logo from "../../assets/logo/logo_white.png";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "react-native-toast-notifications";
const SplashScreen = ({ navigation }) => {
  const toast = useToast();
  const { user, setUserData, clearUserData } = useAuth();
  //method to fetch the user data from aync storage if there is any and login the Dashboard or Home Screen according to the user type
  const _checkTokenExpiration = async () => {
    try {
      // check if authcontext has loaded the user from asynstorage
      if (!user) {
        navigation.replace("landing") // navigate to User Home screen
        return;
      } else {
        // user is defined, we check for token expiration
        var decoded = jwt_decode(user?.token)
        const currentTime = Date.now() / 1000; // to get in milliseconds
        if (decoded.exp < currentTime) {
          // Redirect to login
          clearUserData();
          navigation.replace("landing") // navigate to User Home screen
        } else {
          // we have valid token
          navigation.navigate("tabs", {screen: "buy"})
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  // check the userData and navigate to screens accordingly on initial render
  useEffect(() => {
    _checkTokenExpiration();
  }, []);

  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={logo} />
    </View>
  );
};

export default SplashScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
    alignItems: "center",
    justifyContent: "center",
  },
  splashText: {
    color: colors.light,
    fontSize: 50,
    fontWeight: "bold",
  },
  logo: {
    resizeMode: "contain",
    width: 80,
    height: 80,
  },
});
