import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React, { useState } from "react";
import { Ionicons } from "@expo/vector-icons";
import { colors, network, messages } from "../../constants";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useToast } from "react-native-toast-notifications";
import ProgressDialog from "react-native-progress-dialog";


const ForgetPasswordScreen = ({ navigation }) => {
  const [email, setEmail] = useState("")
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const sendInstructionsHandle = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ email })
    };

    fetch(`${network.serverip}/reset-password`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsLoading(false);
        toast.show("Eğer bu e-posta adresi sistemimizde kayıtlıysa, talimatlar e-postaya gönderilecektir.", { placement: "bottom", type: "success_with_close_button" });
        setEmail("")
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
        console.log("error", error);
      });
  };
  return (
    <View style={styles.container}>
      <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('login');
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Şifre Unuttum</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>
            Hesabınıza ait e-postanızı girin ve şifreyi sıfırlama talimatlarını içeren bir e-posta gönderelim.
          </Text>
        </View>
      </View>
      <View style={styles.formContainer}>
        <CustomInput value={email} setValue={setEmail} placeholder={"E-posta girin"} />
      </View>
      <CustomButton
        isAuthPage={true}
        text={"TALİMAT GÖNDER"}
        onPress={sendInstructionsHandle}
        radius={5}
      />
    </View>
  );
};

export default ForgetPasswordScreen;

const styles = StyleSheet.create({
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  formContainer: {
    marginTop: 10,
    marginBottom: 20,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
  },
});
