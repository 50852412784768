import {
  StyleSheet,
  Text,
  Image,
  StatusBar,
  View,
  KeyboardAvoidingView,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import React, { useEffect, useState } from "react";
import { colors, messages, network } from "../../constants";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { Ionicons } from "@expo/vector-icons";
import * as ImagePicker from "expo-image-picker";
import ProgressDialog from "react-native-progress-dialog";
import { AntDesign } from "@expo/vector-icons";
import DropDownPicker from "react-native-dropdown-picker";
import { useToast } from "react-native-toast-notifications";
import { useAuth } from "../../context/AuthContext";
const EditProductScreen = ({ navigation, route }) => {
  const toast = useToast()
  const { product } = route.params;
  
  const { user, setUserData, clearUserData } = useAuth();
  const [isloading, setIsloading] = useState(false);
  const [label, setLabel] = useState("Yükleniyor...");
  const [author, setAuthor] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [condition, setCondition] = useState("")
  const [categories, setCategories] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const [openForConditionDropdown, setOpenForConditionDropdown] = useState(false);
  const [statusDisable, setStatusDisable] = useState(false);
  const [items, setItems] = useState([]);
  const itemsForConditionDropdown = [
    {
      label: 'Mükemmel',
      value: 'Mükemmel',
    },
    {
      label: 'İyi',
      value: 'İyi',
    },
    {
      label: 'Kabul Edilir',
      value: 'Kabul Edilir',
    }];

  var payload = [];

  //Method : Fetch category data from using API call and store for later you in code
  const fetchCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsloading(true);
    fetch(`${network.serverip}/categories`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setCategories(result.categories);
          result.categories.forEach((cat) => {
            let obj = {
              label: cat,
              value: cat,
            };
            payload.push(obj);
          });
          setItems(payload);
        } else {
          // toast.show(messages.needLoginMessage, {
          //   placement: "bottom",
          //   type: "danger_with_close_button"
          // });
        }
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  };

  //Method for selecting the image from device gallery
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 0.5,
    });
    console.log("error", error);
    if (!result.cancelled) {
      setImage(result.uri);
    }
  };

  const isNumbersOnly = (str) => {
    console.log("isNumbersOnly", str, typeof str);
    // Function to check if a string contains only numbers
    const regex = /^[0-9]+$/;
    // Use the test method to check if the string matches the pattern
    return regex.test(str);
  }

  //Method for imput validation and post data to server to edit product using API call
  const editProductHandle = (event) => {
    setIsloading(true);
    //[check validation] -- Start
    if (title == "") {
      toast.show("Lütfen kitap ismi girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (!isNumbersOnly(price)) {
      toast.show("Lütfen fiyat bölümüne sadece sayı girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (price == 0) {
      toast.show("Lütfen 0'dan büyük bir fiyat girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (!isNumbersOnly(quantity)) {
      toast.show("Lütfen adet bölümüne sadece sayı girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (quantity <= 0) {
      toast.show("Lütfen 0'dan büyük bir ürün miktarı girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (author == "") {
      toast.show("Lütfen yazar girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    } if (description == "") {
      toast.show("Lütfen ürün metni girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (category == "") {
      toast.show("Lütfen ürün kategorisi girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    if (condition == "") {
      toast.show("Lütfen kondisyon girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    } if (image == "") {
      toast.show("Lütfen fotoğraf ekleyin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsloading(false);
      return
    }
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);
    var formdata = new FormData();
    // converts to blob. Source: https://stackoverflow.com/a/53454470
    const blob = dataURItoBlob(image);
    const uploadedImage = new File([blob], "upload.jpg", {
      type: 'image/jpeg'
    });
    formdata.append("image", uploadedImage);
    formdata.append("title", title);
    formdata.append("author", author);
    formdata.append("description", description);
    formdata.append("price", price);
    formdata.append("category", category);
    formdata.append("condition", condition);
    formdata.append("quantity", quantity);

    var formSubmitOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata
    };


    console.log("`${network.serverip}/update-product?id=${product._id}", `${network.serverip}"/update-product?id=${product._id}"`);
    fetch(
      `${network.serverip}/update-product?id=${product._id}`,
      formSubmitOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setIsloading(false);
          toast.show("Kitabınız başarıyla güncellenmiştir", {
            placement: "bottom",
            type: "success_with_close_button"
          });
        }
      })
      .catch((error) => {
        setIsloading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  };

  // set all the input fields and image on initial render
  useEffect(() => {
    fetchCategories();
    console.log("product", product, `${network.serverip}/uploads/${product?.filename}`)
    setImage(`${network.serverip}/uploads/${product?.filename}`);
    setTitle(product.title);
    setPrice(product.price);
    setQuantity(product.quantity);
    setDescription(product.description);
    setAuthor(product.author);
    setCondition(product.condition);
    setCategory(product.category);
  }, []);

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  return (
    <KeyboardAvoidingView style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <ProgressDialog visible={isloading} label={"Yükleniyor..."} />
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            // navigation.replace("viewmyproducts");
            navigation.navigate("viewmyproducts");
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Yeni Kitap Satışa Sun</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>Yeni Kitap Detaylarını Aşağıya Girin.</Text>
        </View>
      </View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, width: "100%" }}
      >
        <View style={styles.formContainer}>
          <View style={styles.imageContainer}>
            <Text style={{ color: colors.muted }}>Kitabın Fotoğrafını Çekin</Text>
            {image ? (
              <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
                <Image
                  source={{ uri: image }}
                  style={{ width: 200, height: 200 }}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity style={styles.imageHolder} onPress={pickImage}>
                <AntDesign name="pluscircle" size={50} color={colors.muted} />
              </TouchableOpacity>
            )}
          </View>
          <Text style={{ color: colors.muted }}>Kitap İsmi</Text>
          <CustomInput
            value={title}
            setValue={setTitle}
            placeholder={"Kitap İsmi"}
            placeholderTextColor={colors.muted}
            radius={5}
          />
          <Text style={{ color: colors.muted }}>Yazar İsmi</Text>
          <CustomInput
            value={author}
            setValue={setAuthor}
            placeholder={"Yazar"}
            placeholderTextColor={colors.muted}
            radius={5}
          />
          <Text style={{ color: colors.muted }}>Kitap Fiyatı</Text>
          <CustomInput
            value={price}
            setValue={setPrice}
            placeholder={"Fiyat"}
            keyboardType={"number-pad"}
            placeholderTextColor={colors.muted}
            radius={5}
          />
          <Text style={{ color: colors.muted }}>Kitap Adeti</Text>
          <CustomInput
            value={quantity}
            setValue={setQuantity}
            placeholder={"Adet"}
            keyboardType={"number-pad"}
            placeholderTextColor={colors.muted}
            radius={5}
          />
           <Text style={{ color: colors.muted }}>Ürün Açıklaması</Text>
          <CustomInput
            multiline
            editable
            numberOfLines={4}
            maxLength={10000}
            height={250}
            value={description}
            setValue={setDescription}
            placeholder={"Ürün Açıklaması"}
            placeholderTextColor={colors.muted}
            radius={5}
          />
          {/* <SearchableDropdown
          multi={true}
          onTextChange={(text) => {}}
          onItemSelect={(item) => {}}
          defaultIndex={0}
          items={[
            {
              label: 1,
              value: 'JavaScript',
            },
            {
              label: 2,
              value: 'Java',
            },
            {
              label: 3,
              value: 'Ruby',
            },
            {
              label: 4,
              value: 'React Native',
            },
            {
              label: 5,
              value: 'PHP',
            },
            {
              label: 6,
              value: 'Python',
            },
            {
              label: 7,
              value: 'Go',
            },
            {
              label: 8,
              value: 'Swift',
            },
          ]}
          containerStyle={{
          borderRadius: 5,
          width: "100%",
          elevation: 5,
          zIndex: 20,
          top: -20,
          maxHeight: 300,
          backgroundColor: colors.light,
        }}
        textInputStyle={{
          borderRadius: 10,
          padding: 6,
          paddingLeft: 10,
          borderWidth: 0,
          backgroundColor: colors.white,
        }}
        itemStyle={{
          padding: 10,
          marginTop: 2,
          backgroundColor: colors.white,
          borderColor: colors.muted,
        }}
        itemTextStyle={{
          color: colors.muted,
        }}
        itemsContainerStyle={{
          maxHeight: "100%",
        }}
        placeholder="Bütün Kitapları Ara..."
        resetValue={false}
        underlineColorAndroid="transparent"
      /> */}
       <Text style={{ color: colors.muted }}>Kitap Kondisyonu</Text>
          <DropDownPicker
            placeholder={"Kitap Kondisyonu Seçin"}
            open={openForConditionDropdown}
            value={condition}
            items={itemsForConditionDropdown}
            setOpen={setOpenForConditionDropdown}
            setValue={setCondition}
            disabled={statusDisable}
            disabledStyle={{
              backgroundColor: colors.light,
              borderColor: colors.white,
            }}
            style={{ borderColor: "#fff", elevation: 5 }}
          />
          <View style={{ height: '10px' }}></View>
          <Text style={{ color: colors.muted }}>Kitap Kategorisi</Text>
          <DropDownPicker
            searchable={true}
            language="TR"
            placeholder={"Kitap Kategorisi Seçin"}
            open={open}
            value={category}
            items={items}
            setOpen={setOpen}
            setValue={setCategory}
            disabled={statusDisable}
            disabledStyle={{
              backgroundColor: colors.light,
              borderColor: colors.white,
            }}
            style={{ borderColor: "#fff", elevation: 5 }}
          />
        </View>
      </ScrollView>
      <View style={styles.buttonContainer}>
        <CustomButton text={"KİTABI GÜNCELLE"} onPress={(e) => editProductHandle(e)} />
      </View>
    </KeyboardAvoidingView>
  );
};

export default EditProductScreen;

const styles = StyleSheet.create({
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  formContainer: {
    flex: 2,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },

  buttonContainer: {
    marginTop: 10,
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: 250,
    backgroundColor: colors.white,
    borderRadius: 10,
    elevation: 5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  imageHolder: {
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.light,
    borderRadius: 10,
    elevation: 5,
  },
});
