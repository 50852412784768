import { StyleSheet, Text, View, TouchableOpacity, Image, FlatList, TextInput } from 'react-native'
import React, { useState } from 'react'
import ReplyLogo from "../../assets/icons/reply.png";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import { colors, messages, network, DATE_FORMAT_OPTIONS, formatDateStringToHumanReadable } from '../../constants';
import { useToast } from "react-native-toast-notifications";
import ProgressDialog from "react-native-progress-dialog";

const CommentItem = ({ commentItem, user, updateParentCommentsList }) => {
    const toast = useToast();
    const [newReply, setNewReply] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const { productId, _id, username, replies, commentText, createdAt } = commentItem;

    if (createdAt == "") {
        createdAt = new Date().now()
    }
    const [showInput, setShowInput] = useState(false);
    const [replyList, setReplyList] = useState(replies);




    const handleClear = () => {
        setNewReply("");
        setShowInput(false);
    }

    const handleAddReply = () => {
        if (newReply.trim() === '') return;
        setIsLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        myHeaders.append('Content-Type', 'application/json');
        var addCommentRequestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
                replyText: newReply,
            }),
        };
        console.log("commentItem before fetch", commentItem)
        fetch(`${network.serverip}/product/${productId}/comments/${_id}/add-reply`, addCommentRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result?.err === "jwt expired") {
                    toast.show(messages.needLoginMessage, {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                    setIsLoading(false);
                    logout();
                }
                if (result.success) {
                    // setProductComments(result.data);
                    setIsLoading(false);
                    setReplyList((prevState) => [result.addedReply, ...prevState]);
                    console.log("replyList nside common item", replyList)

                    setNewReply('');

                    toast.show("Yorumunuz eklendi.", {
                        placement: "bottom",
                        type: "success_with_close_button"
                    });
                    setShowInput(false);
                } else {
                    toast.show(messages.needLoginMessage, {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
        setIsLoading(false);
    };

    return (
        <View key={commentItem.id} style={{ borderWidth: 1, borderColor: 'gray', width: "100%", padding: 10, marginVertical: 5, backgroundColor: "white"}}>
            <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
            <View style={styles.containerForUserNameAndDate}>
                <Text style={{ fontWeight: '600', fontSize: 16 }}>{username}{" "}<Text style={styles.commentTextDate}>{formatDateStringToHumanReadable(createdAt)}</Text></Text>
            </View>
            <Text>{commentText}</Text>
            {showInput ?
                (<View style={styles.commentInputContainer}>
                    <TextInput onChangeText={e => setNewReply(e)} onSubmitEditing={() => handleAddReply()} style={styles.commentInput} value={newReply} placeholder={"Yorum girin"} />
                    <TouchableOpacity
                        disabled={false}
                        style={styles.commentButton}
                        onPress={() => {
                            handleAddReply();
                        }}
                    >
                        <AntDesign name="arrowright" size={24} color="white" style={styles.searchIcon} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.clearButton} onPress={handleClear}>
                        <AntDesign name="close" size={24} color="white" />
                    </TouchableOpacity>
                </View>) :

                <TouchableOpacity style={styles.replyContainer} onPress={() => {
                    setShowInput(true);
                }}>
                    <View style={styles.replyContainerButton}>
                        <FontAwesome name="reply" size={24} color="black" />
                        <Text style={styles.replyLogoText}>Cevap Ver</Text>
                    </View>
                </TouchableOpacity>
            }

            {/* <View style={{ flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>
                <TouchableOpacity id={commentList.id} onPress={deleteButton} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                    <Image source={{ uri: 'https://assets.ccbp.in/frontend/react-js/comments-app/delete-img.png' }} style={{ height: 20, width: 20 }} />
                    <Text style={{ marginLeft: 5 }}>Degistir</Text>
                </TouchableOpacity>
                <TouchableOpacity id={commentList.id} onPress={deleteButton} style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                    <Image source={{ uri: 'https://assets.ccbp.in/frontend/react-js/comments-app/delete-img.png' }} style={{ height: 20, width: 20 }} />
                    <Text style={{ marginLeft: 5 }}>Sil</Text>
                </TouchableOpacity>
            </View> */}

            {replyList.length > 0 &&
                <View style={styles.repliesContainer}>
                    {replyList.map((item) => {
                        return (
                            <View key={item._id} id={item._id} style={{ borderWidth: 1, borderColor: 'gray', width: "100%", padding: 10, marginVertical: 5 }}>
                                <View key={item._id} style={styles.containerForUserNameAndDate}>
                                    <Text style={{ fontWeight: '600', fontSize: 16 }}>{item.username}{" "}<Text style={styles.commentTextDate}>{formatDateStringToHumanReadable(item.createdAt || Date.now())}</Text></Text>
                                </View>
                                <Text>{item.replyText}</Text>
                            </View>
                        );
                    })}
                </View>
            }
        </View>
    )
}

export default CommentItem;

const styles = StyleSheet.create({
    containerForUserNameAndDate: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    commentTextDate: {
        fontSize: 14,
        opacity: .5
    },
    replyItem: {
        padding: 20,
        fontSize: 15,
        marginTop: 5,
    },
    repliesContainer: {
        // padding: 50,
        marginLeft: 10,
        paddingLeft: 10,
        borderLeftColor: 'grey',
        borderLeftWidth: 3,
    },
    clearButton: {
        flex: 1,
        backgroundColor: "black",
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
    },
    searchIcon: {
   // justifyContent: "center",
        // alignItems: "center",
    },
    commentButton: {
        backgroundColor: colors.lightpink,
        borderRadius: 5,
        padding: 10,
        flex: 1,
        // width: "10%",
        justifyContent: "center",
        alignItems: "center",
    },
    commentInput: {
        flex: 8,
        borderWidth: 2,
        backgroundColor: colors.bisque,
        // outlineColor: colors.lightpink,
        backgroundColor: "#fff",
        padding: 8,
        borderRadius: 5,
        justifyContent: "flex-start",
    },
    replyLogo: {
        // width: 16,
        marginRight: 5,
        // position: 'absolute',
        // tintColor: 'rgba(0, 0, 0, 0.67)', // Simulate "invert(67%)"
    },
    replyLogoText: {
        fontSize: 14,
        fontWeight: "600",
        paddingLeft: 5
    },
    replyContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems:"center",
        borderRadius: 4,
        padding: 5,
        width: "fit-content"
    },
    replyContainerButton:{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        backgroundColor: "lightgray",
        padding: 10,
        borderRadius: 5
    },
    commentInputContainer: {
        display: "flex",
        flexDirection: 'row',
        // alignItems: 'space-between',
        // justifyContent:"center",
        width: "100%",
        backgroundColor: '#fff',
        borderRadius: 5,
        backgroundColor: "#f3f3f3",
        // width: "auto"
        // marginHorizontal: 10,
        // paddingHorizontal: 10,
    },
})