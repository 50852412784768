const colors = {
  primary: "#CC6600",
  primary_light: "#FFC8B2",
  primary_shadow: "#FB6A04",
  secondary: "#31C4FB",
  tertiary: "#AEE8FD",
  success: "#90ee90",
  danger: "#FF4848",
  shadow: "#E7E8EA",
  warning: "#FBD431",
  info: "#F8F9FA",
  light: "#F5F5F5",
  dark: "#343A3F",
  muted: "#707981",
  white: "#FFFFFF",
  lightgreen: "lightgreen",
  bisque: "#FFE4C4",
  orange: "#FFA900",
  blue: "#1089ff",
  yellow:"#ffff64d9",
  pink: "#ff0068ab",
  nicepink: "#ED4A66",
  lightpink: "#FF0068"
  
};
export default colors;

export const customStylesForStepIndicator = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize: 30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#fe7013',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#fe7013',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#fe7013',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#fe7013',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#fe7013',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#fe7013'
};
